import { useState, useCallback } from 'react';

export const useHttp = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const request = useCallback(async (url, method = 'GET', body = null, headers = { 'Content-Type': 'application/json' }) => {

        setLoading(true);

        if (body) {
            body = JSON.stringify(body);
        }

        try {
            const response = await fetch(url, { method, body, headers: headers });

            if (!response.ok) {
                const responseBody = await response.json(); // Пытаемся распарсить тело ответа как JSON

                console.error("Error response from server:", responseBody); // Выводим ответ в консоль
                throw new Error(`Could not fetch ${url}, status: ${response.status}`);
            }

            // Проверяем, есть ли контент в ответе перед тем как пытаться его преобразовать в JSON
            const text = await response.text();
            let data = null;

            if (text.length > 0) {
                data = JSON.parse(text);
            }
            setLoading(false);

            if(data){

                return data;
            }else{
                return response;
            }


        } catch (e) {
            // console.log(e);
            setLoading(false);
            setError(e.message);
            throw e;

        }

    }, []);

    const clearError = useCallback(() => setError(null), []);

    return { loading, request, error, clearError };
}