import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';


import useBazarkenService from '../../services/BazarkenService';
import { Skeleton, ErrorMessage } from '../pages/index';
import Modal from './Modal';

import './styles/topBreadcrumbs.scss';

const OrderListPage = () => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [currentAction, setCurrentAction] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedOrderAddress, setSelectedOrderAddress] = useState(null);
    const [customer, setCustomer] = useState(null);
    const { loading, error, getShopOrders, nextStatus, getAddress, getUser } = useBazarkenService();
    const { storeId } = useParams();

    // Следим за состоянием модального окна
    const [modalIsOpen, setModalIsOpen] = useState(false);
    // Переключаем модальное окно
    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen)
    };

    useEffect(() => {
        onRequest();
    }, []) // Если подставить пустой массив то функция вызовется только один раз

    const onRequest = () => {
        getShopOrders(storeId)
            .then(onOrdersLoaded)
    }

    const onOrdersLoaded = (newOrders) => {
        const sortedOrders = newOrders.items.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));

        setOrders(sortedOrders)
    }

    const calculateTotalProductsPrice = (products) => {
        return products.reduce((acc, product) => acc + product.totalPrice, 0);
    }

    const handleStatusChange = (selectedOption) => {
        setSelectedStatus(selectedOption.value);
    };

    const paymentMethod = (method) => {
        console.log(method)
        switch (method) {
            case "cash":
                return 'Наличные';
            case "cashWithExchange":
                return 'Наличные со сдачей';
            default:
                // Если переданный метод оплаты не поддерживается
                return method;
        }
    }


    function renderForm() {
        if (currentAction === "orderDetails") {
            return (
                <>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope='col' style={{ "width": "67px" }}>Фото</th>
                                <th scope='col' className='text-left'>Название</th>
                                <th scope='col'>Производитель</th>
                                <th scope='col'>Объем</th>
                                <th scope='col'>Артикул</th>
                                <th scope="col" style={{ "width": "65px" }}>Цена</th>
                                <th scope="col" style={{ "width": "65px", "whiteSpace": "nowrap" }}>Кол-во</th>
                            </tr>
                        </thead>

                        <tbody>
                            {selectedOrder.products.map(product => (
                                <tr key={product.id}>
                                    <th>
                                        <picture>
                                            <img src={product.productInfo.imageURL} alt={product.productInfo.name} />
                                        </picture>
                                    </th>
                                    <td className='text-left'>{product.productInfo.name}</td>
                                    <td style={{ 'textAlign': 'center' }}>{product.productInfo.manufacturer}</td>
                                    <td style={{ 'textAlign': 'center' }}>{product.productInfo.size} {product.productInfo.measurement}</td>
                                    <td style={{ 'textAlign': 'center' }}>{product.productInfo.article}</td>
                                    <td style={{ 'textAlign': 'center' }}>{product.productInfo.price}</td>
                                    <td style={{ 'textAlign': 'center' }}>{product.quantity}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <p style={{ "padding": "0 15px", "marginBottom": "-15px" }}><span>Заказчик: </span>{customer ? (customer.firstname || '') + ' ' + (customer.surname || '') : ''}</p>
                    <p style={{ "padding": "0 15px", "marginBottom": "-15px" }}><span>Телефон: </span>{customer && customer.phone ? customer.phone : ''}</p>
                    <p style={{ "padding": "0 15px", "marginBottom": "-15px" }}><span>Адрес: </span>{selectedOrderAddress ? selectedOrderAddress.address : ''}</p>
                    <p style={{ "padding": "0 15px", "marginBottom": "-15px" }}><span>Оплата: </span>{paymentMethod(selectedOrder.paymentMethod)}</p>

                    <div className="form-item submit">
                        <button type="submit" >Закрыть</button>
                    </div>
                </>
            )
        }

        if (currentAction === "changeOrderStatus") {
            const statusOptions = Object.entries(statusTranslations).map(([key, value]) => {
                return { value: key, label: value }
            });
            return (
                <div style={{ "padding": "38px 80px 38px 80px" }}>
                    <label id="labelProductStatus" htmlFor="productStatus">Статус заказа</label>
                    <Select
                        id="productStatus"
                        isLoading={loading}
                        placeholder="Выберите статус"
                        options={statusOptions}
                        aria-labelledby="labelProductStatus"
                        onChange={handleStatusChange}
                        isRequired
                    />
                </div>
            )
        }
    }

    const showProducts = (id) => {
        const selectedOrder = orders.find(item => item.id === id);

        setSelectedOrder(selectedOrder)
        setCurrentAction("orderDetails")
        getUser(selectedOrder.buyer)
            .then(setCustomer)
        getAddress(selectedOrder.deliveryAddress)
            .then(setSelectedOrderAddress)  // Передача результата в getAddress
        toggleModal()
    }


    // const changeOrderStatus = (id) => {
    //     setSelectedOrder(orders.find(item => item.id === id))
    //     setCurrentAction("changeOrderStatus")
    //     toggleModal()
    // }

    const setNextStatus = (id) => {
        const isConfirmed = window.confirm("Сменить статус заказа на следующий?");

        if (isConfirmed) {
            // Выполните действие после подтверждения
            nextStatus(storeId, id)
                .then(onRequest)
        }
    }

    const handleFormSubmit = (event) => {
        // Предотвращаем обновление страницы
        event.preventDefault();
        toggleModal()
        setCustomer(null)
        setSelectedOrderAddress(null)
    }

    const statusTranslations = {
        created: "Создан",
        paid: "Оплачен",
        assembling: "Собирается",
        inDelivery: "В доставке",
        completed: "Завершен",
        cancelled: "Отменен"
    };

    function renderItems(arr) {
        const items = arr.map((item, i) => {

            return (
                <tr key={item.id}>
                    <th scope="row" style={{ "paddingLeft": 0, "paddingRight": 0 }}>
                        <button onClick={() => showProducts(item.id)}>
                            <picture>
                                <svg style={{ "padding": 0 }} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25 49.9999C38.6765 49.9999 50 38.682 50 25.0122C50 11.3669 38.6519 0.0244141 24.9755 0.0244141C11.3235 0.0244141 0 11.3669 0 25.0122C0 38.682 11.348 49.9999 25 49.9999ZM17.4755 37.4327C14.7059 37.4327 13.3088 36.0853 13.3088 33.3904V19.7452C13.3088 17.0259 14.7059 15.703 17.4755 15.703H19.5588C19.6078 12.9103 22.0098 10.5585 25 10.5585C27.9901 10.5585 30.3922 12.9103 30.4411 15.703H32.5245C35.2941 15.703 36.691 17.0504 36.691 19.7452V33.3904C36.691 36.0853 35.3187 37.4327 32.9411 37.4327H17.4755ZM22.0098 15.703H27.9901C27.9165 13.9882 26.7891 12.8858 25 12.8858C23.2108 12.8858 22.0833 13.9882 22.0098 15.703Z" fill="black" fillOpacity="0.85"></path>
                                </svg>
                            </picture>
                        </button>
                    </th>

                    <td className='text-center'>
                        {new Date(item.creationDate).toLocaleDateString('ru-RU', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        })}
                    </td>

                    <td className='text-center'>
                        {new Date(item.creationDate).toLocaleTimeString('ru-RU', {
                            hour: '2-digit',
                            minute: '2-digit'
                        })}
                    </td>

                    <td className="text-center">
                        {item.products.length}
                    </td>

                    <td className="text-center">
                        {calculateTotalProductsPrice(item.products)}
                    </td>

                    <td className="text-center">
                        {item.deliveryPrice}
                    </td>

                    <td className="text-center">
                        {calculateTotalProductsPrice(item.products) + item.deliveryPrice}
                    </td>

                    <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center">
                            <span>
                                {item.statuses && item.statuses[0] ? statusTranslations[item.statuses[0].status] : 'Не определен'}
                            </span>
                            <button onClick={() => setNextStatus(item.id)} type="button" label="Следующий статус" style={{ "paddingTop": "2px" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 473.654 473.654">
                                    <g>
                                        <circle cx="236.827" cy="236.827" r="236.827" fill="#9dbdb0" data-original="#4abc96" opacity="1">
                                        </circle>
                                        <path d="m358.075 216.091-81.029-81.033c-25.56-25.564-65.08 14.259-39.456 39.883 11.009 11.009 22.019 22.019 33.028 33.032H140.563c-36.225 0-36.543 56.109-.404 56.109h130.47l-33.252 33.252c-25.564 25.56 14.259 65.08 39.883 39.456 27.011-27.007 54.018-54.014 81.029-81.025 10.844-10.849 10.549-28.915-.214-39.674z" fill="#ffffff"></path>
                                    </g>
                                </svg>
                            </button>
                            {/* <button onClick={() => changeOrderStatus(item.id)} type="button" style={{ "padding": "0 5px" }}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1912_10946)">
                                        <path d="M2.66482 14.9228L13.3191 4.27827L11.6101 2.55952L0.94607 13.2138L0.0183355 15.3818C-0.0695552 15.6161 0.174585 15.8896 0.40896 15.7919L2.66482 14.9228ZM14.1785 3.43843L15.1648 2.47163C15.6629 1.97358 15.6824 1.43647 15.2429 0.987257L14.9109 0.655226C14.4715 0.215773 13.9344 0.264601 13.4363 0.743117L12.45 1.71968L14.1785 3.43843Z" fill="#9DBDB0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1912_10946">
                                            <rect width="15.5564" height="15.8121" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button> */}
                        </div>
                    </td>
                </tr>
            )
        });
        return (
            <>
                {items}
            </>
        )
    }

    const items = renderItems(orders);
    const errorMessage = error ? <ErrorMessage /> : null;
    const skeleton = loading ? <Skeleton pageName={"OrderList"} /> : null;

    return (
        <>
            <div className="container d-flex justify-content-between align-items-center">
                <ul className="breadcrumbs">
                    <li>
                        <h1 style={{ "padding": "20px 0", "margin": "-20px 0", "lineHeight": "normal" }}>Заказы</h1>
                    </li>
                </ul>
                {!error
                    ?
                    <div className="wrapper d-flex align-items-center" style={{ "gap": "20px" }}>

                    </div>
                    :
                    null
                }

            </div>

            {errorMessage}

            {
                !error ?
                    <>
                        <div className="catalog">
                            <div className="container">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ "width": "67px" }} scope="col" className='text-left'>Подробнее</th>
                                            <th scope="col">Дата</th>
                                            <th scope="col">Время</th>
                                            <th scope="col">Товары</th>
                                            <th scope="col">Сумма заказа</th>
                                            <th scope="col">Стоимость доставки</th>
                                            <th scope="col">Итого</th>
                                            <th scope="col">Статус</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {skeleton}
                                        {items}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <Modal modalIsOpen={modalIsOpen} toggleModal={toggleModal} renderForm={renderForm} handleFormSubmit={handleFormSubmit} />
                    </>
                    : null
            }
        </>
    )
}

export default OrderListPage;
