import Select from 'react-select';

const Search = ({ manufacturers, products, selectedManufacturer, setSelectedManufacturer, selectedProduct, setSelectedProduct, loading, setFilteredProducts }) => {
    const manufacturersOptions = manufacturers.map((item) => {
        return { value: item.id, label: item.name }
    })

    // Создаем Map с продуктами для устранения дубликатов
    const productMap = new Map(products.map((item) => [item.name, { value: item.id, label: item.name }]));
    // Преобразуем Map обратно в массив продуктов
    const productOptions = Array.from(productMap.values());

    const handleManufacturerChange = (selectedOption) => {
        setSelectedManufacturer(selectedOption?.value);
    };

    const handleProductChange = (selectedOption) => {
        setSelectedProduct(selectedOption?.value);
    }

    return (
        <form id="allProductList" className="product-list__filters">
            <div className="form-item">
                <Select
                    id="selectProduct"
                    isLoading={loading}
                    placeholder="Введите название"
                    options={productOptions}
                    onChange={handleProductChange}
                    isClearable
                />
                <input type="hidden" name="selectProductId" value={selectedProduct ?? ''} />
            </div>

            <div className="form-item">
                <Select
                    id="selectProductManufacturer"
                    isLoading={loading}
                    placeholder="Выберите производителя"
                    options={manufacturersOptions}
                    onChange={handleManufacturerChange}
                    isClearable
                />
                <input type="hidden" name="selectManufacturerId" value={selectedManufacturer ?? ''} />
            </div>
        </form>
    )
}

export default Search;
