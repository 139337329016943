import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import useBazarkenService from '../../services/BazarkenService';
import Modal from './Modal';
import MoneyInfoWidget from './widgets/MoneyInfoWidget';
import { Skeleton, ErrorMessage } from '../pages/index';

import './styles/profileListPage.scss';

const ProfileListPage = () => {
    // Следим за состоянием модального окна
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentAction, setCurrentAction] = useState(null);
    const [selectedShop, setSelectedShop] = useState(null);
    const [selectedStore, setSelectedStore] = useState(null);

    // Переключаем модальное окно
    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen)
    };

    const [storeList, setStoreList] = useState([]);
    const { loading, error, clearError, getAdminAllStores, addStore, setWorkzone, banStore, unbanStore } = useBazarkenService();

    useEffect(() => {
        onRequest();
    }, []) // Если подставить пустой массив то функция вызовется только один раз

    const onRequest = () => {
        clearError()
        getAdminAllStores()
            .then(onStoreListLoaded)
    }

    const onStoreListLoaded = (newStoreList) => {
        setStoreList([...newStoreList.items])
    }

    const addNewStore = (inn, name, ogrn, about, deliveryPrice) => {
        clearError()
        return addStore(inn, name, ogrn, about, deliveryPrice)
            .then(onRequest)
            .then(toggleModal)
    }

    const handleFormSubmit = (event) => {
        // Предотвращаем обновление страницы
        event.preventDefault();

        if (currentAction === 'newShop') {
            // Получаем значение из поля ввода
            const inn = event.target.elements.inn.value;
            const name = event.target.elements.name.value;
            const ogrn = event.target.elements.ogrn.value;
            const about = event.target.elements.about.value;
            const deliveryPrice = event.target.elements.deliveryPrice.value;
            // Вызываем функцию addNewStore с полученным значением
            addNewStore(inn, name, ogrn, about, deliveryPrice)
                .then(() => {
                    // Получаем все элементы input
                    let inputs = document.getElementsByTagName('input');

                    // Проходимся по каждому элементу input
                    for (let i = 0; i < inputs.length; i++) {
                        // Если тип поля text, email, password, etc., то очищаем его
                        if (inputs[i].type === 'text' || inputs[i].type === 'password' || inputs[i].type === 'email') {
                            inputs[i].value = ''; // Устанавливаем значение в пустую строку
                        }
                    }

                });
        } else if (currentAction === 'setGeolocation') {
            const coords = event.target.elements.coords.value;

            setWorkzone(selectedShop, coords)
                .then(toggleModal)
        }

    }

    const newShop = () => {
        setCurrentAction('newShop')
        setSelectedShop(null)
        toggleModal()
    }

    const setGeolocation = (id) => {
        setCurrentAction('setGeolocation')
        setSelectedShop(id)
        toggleModal()
    }

    const ban = (id) => {
        banStore(id)
            .then(onRequest)
    }

    const unban = (id) => {
        unbanStore(id)
            .then(onRequest)
    }

    const showMoneyInfo = (store) => {
        setCurrentAction('moneyInfo')
        setSelectedStore(store)
        toggleModal()
    }

    function renderForm() {

        if (currentAction === 'newShop') {
            return (
                <>
                    <div className="form-item">
                        <label>Название магазина</label>
                        <input name="name" type="text" defaultValue="" placeholder='' required />
                    </div>

                    <div className="form-item">
                        <label>Описание</label>
                        <input name="about" type="text" defaultValue="" placeholder='' />
                    </div>

                    <div className="form-item">
                        <label>ИНН</label>
                        <input name="inn" type="text" defaultValue="" placeholder='' required />
                    </div>

                    <div className="form-item">
                        <label>ОГРН</label>
                        <input name="ogrn" type="text" defaultValue="" placeholder='' required />
                    </div>

                    <div className="form-item">
                        <label>Цена доставки</label>
                        <input name="deliveryPrice" type="number" defaultValue="" placeholder='' required />
                    </div>

                    <div className="form-item submit">
                        <button type="submit">Сохранить</button>
                    </div>
                </>
            )
        }

        if (currentAction === 'setGeolocation') {
            return (
                <>
                    <div className="form-item">
                        <label>Скопированные координаты *</label>
                        <textarea rows={20} name="coords" type="text" defaultValue="" placeholder='{
                            "coordinates": [
                                [
                                    [
                                        -10.0000000,
                                        10.0000000
                                    ],
                                    [
                                        -10.0000000,
                                        10.0000000
                                    ],
                                    [
                                        -10.0000000,
                                        10.0000000
                                    ],
                                ]
                            ],
                            "type": "Polygon"
                            }' style={{ "overflowY": "scroll" }} />
                    </div>

                    <p>* - Чтобы задать область доставки перейдите на <Link to={"https://www.keene.edu/campus/maps/tool/"} target='_blank' style={{ "color": "#9dbdb0" }}>САЙТ</Link>. Найдите на карте ваш город, выделите с помощью правой кнопкой мыши область доставки. НЕ КЛИКАЙТЕ ПО ПЕРВОЙ ТОЧКЕ, вместо этого нажмите Close Shape и выделенная область замкнется.<br></br>Затем скопируйте информацию которая находится в фигурных скобках "&#123;...&#125;", вместе со скобками и вставьте в поле выше.</p>

                    <div className="form-item submit">
                        <button type="submit">Сохранить</button>
                    </div>
                </>
            )
        }

        if (currentAction === 'moneyInfo') {
            return (
                <>
                    {selectedStore ?
                        <>
                            <h2 style={{ "marginBottom": "-10px", "minHeight": "24px" }}>{selectedStore.name}</h2>
                            <MoneyInfoWidget storeId={selectedStore.id} />
                            <div className="modal-footer" style={{ "gridTemplateColumns": "1fr" }}>
                                <button type="button" onClick={toggleModal}>Закрыть</button>
                            </div>
                        </>
                        : null
                    }
                </>
            )
        }

        return <></>
    }

    function renderItems(arr) {
        const items = arr.map((item, i) => {

            function renderUsers(arr) {

                const users = arr.map((user, i) => {

                    return (
                        <li key={user.user.id}>
                            <span>Имя</span>
                            <span>
                                {user?.user?.firstname ? `${user.user.firstname} ` : null}
                                {(user?.user?.lastname) ? user.user.lastname : null}
                                {(!user?.user?.lastname && !user?.user?.firstname) ? 'Не указано' : null}
                            </span>
                        </li>
                    )
                });

                const partnersHeader = (users, item) => {
                    if (users.length) {
                        return (
                            <span>{users.length > 1 ? 'Партнеры' : 'Партнер'}</span>
                        );
                    }

                    return (
                        <Link to={`/shops/partners/${item.store.id}`} style={{ 'color': '#FF5E5E' }}>Требуется привязать партнера</Link>
                    )
                }

                return (
                    <>

                        <li>
                            <ul className="account__category">

                                <li>
                                    {partnersHeader(users, item)}
                                </li>

                            </ul>
                        </li>
                        {users}
                        {!item.store.workZone ?
                            <li onClick={() => setGeolocation(item.store.id)} style={{ "cursor": "pointer" }}>
                                <p style={{ "color": "#ff5e5e" }}>Требуется задать зону доставки</p>
                            </li>
                            : null
                        }
                    </>
                )

            }

            const users = renderUsers(item.users);

            return (
                <li key={item.store.id} className="account__item">
                    <div className="account__title">
                        <h2 className='text-center'><Link to={`/shops/${item.store.id}`}>{item.store.name}</Link>
                            {item.store.aboutPartner ?
                                <p style={{ 'color': '#8F9092', "fontSize": "16px", 'margin': '10px 0 0' }}>{item.store.aboutPartner}</p>
                                : null
                            }
                        </h2>
                        <button onClick={() => showMoneyInfo(item.store)} type="button" className="moneyInfo">
                            Доход <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 500 500">
                                <g>
                                    <path d="M310.2 198.13c0 15.59-3 25.47-9.5 30.41-6.08 4.57-18.63 6.85-37.26 6.85h-39.17v-74.15h39.17c18.63 0 30.8 2.29 37.26 7.23 6.46 4.53 9.5 14.45 9.5 29.66z" fill="#9dbdb0" opacity="1"></path>
                                    <path d="M0 250c0 138.07 111.93 250 250 250s250-111.93 250-250S388.07 0 250 0 0 111.93 0 250zm357 20.37c-18.25 13.69-43 17.49-85.93 17.49h-46.8v23.95h66.54c4.19 0 6.09 1.91 6.09 5.71v27c0 3.81-1.9 5.71-6.09 5.71h-66.54v43.72c0 4.95-1.14 6.09-5.7 6.09h-58.94c-4.56 0-5.7-1.14-5.7-6.09v-43.73H125c-4.18 0-6.08-1.9-6.08-5.71v-27c0-3.8 1.9-5.71 6.08-5.71h28.9v-38.4H125c-4.18 0-6.08-1.9-6.08-5.7v-26.61c0-3.8 1.9-5.7 6.08-5.7h28.9V109.91c0-3.42 1.9-4.94 5.7-5.32 37.27-3 74.53-4.56 111.41-4.56 41.83 0 70.72 6.84 86.31 20.15 16 13.31 24 38.78 24 76-.01 38.07-6.1 60.5-24.32 74.19z" fill="#9dbdb0" opacity="1"></path>
                                </g>
                            </svg>
                        </button>

                        <div className="bottom-controls">
                            <Link className="edit" to={`/shops/${item.store.id}`}>
                                <span>РЕДАКТИРОВАТЬ КАТАЛОГ</span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1912_10946)">
                                        <path d="M2.66482 14.9228L13.3191 4.27827L11.6101 2.55952L0.94607 13.2138L0.0183355 15.3818C-0.0695552 15.6161 0.174585 15.8896 0.40896 15.7919L2.66482 14.9228ZM14.1785 3.43843L15.1648 2.47163C15.6629 1.97358 15.6824 1.43647 15.2429 0.987257L14.9109 0.655226C14.4715 0.215773 13.9344 0.264601 13.4363 0.743117L12.45 1.71968L14.1785 3.43843Z" fill="#9DBDB0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1912_10946">
                                            <rect width="15.5564" height="15.8121" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Link>

                            <button type='button' onClick={() => setGeolocation(item.store.id)}>
                                <span className='uppercase' style={{ "paddingRight": "11px", "color": !item.store.workZone ? '#ff5e5e' : undefined }}>Геолокация доставки</span>
                                <svg style={{ "marginBottom": "-5px" }} xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" x="0" y="0" viewBox="0 0 512 512">
                                    <g>
                                        <path d="M341.476 338.285c54.483-85.493 47.634-74.827 49.204-77.056C410.516 233.251 421 200.322 421 166 421 74.98 347.139 0 256 0 165.158 0 91 74.832 91 166c0 34.3 10.704 68.091 31.19 96.446l48.332 75.84C118.847 346.227 31 369.892 31 422c0 18.995 12.398 46.065 71.462 67.159C143.704 503.888 198.231 512 256 512c108.025 0 225-30.472 225-90 0-52.117-87.744-75.757-139.524-83.715zm-194.227-92.34a15.57 15.57 0 0 0-.517-.758C129.685 221.735 121 193.941 121 166c0-75.018 60.406-136 135-136 74.439 0 135 61.009 135 136 0 27.986-8.521 54.837-24.646 77.671-1.445 1.906 6.094-9.806-110.354 172.918L147.249 245.945zM256 482c-117.994 0-195-34.683-195-60 0-17.016 39.568-44.995 127.248-55.901l55.102 86.463a14.998 14.998 0 0 0 25.298 0l55.101-86.463C411.431 377.005 451 404.984 451 422c0 25.102-76.313 60-195 60z" fill={item.store.workZone ? "#9dbdb0" : "#ff5e5e"} data-original="#000000" opacity="1"></path>
                                        <path d="M256 91c-41.355 0-75 33.645-75 75s33.645 75 75 75 75-33.645 75-75-33.645-75-75-75zm0 120c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" fill={item.store.workZone ? "#9dbdb0" : "#ff5e5e"} data-original="#000000" opacity="1"></path>
                                    </g>
                                </svg>
                            </button>

                            <button className="del d-none">
                                <span>УДАЛИТЬ МАГАЗИН</span>
                                <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_2070_8907)">
                                        <path d="M5.16602 4.46289H6.71875V2.37305C6.71875 1.81641 7.10938 1.45508 7.69531 1.45508H11.1914C11.7773 1.45508 12.168 1.81641 12.168 2.37305V4.46289H13.7207V2.27539C13.7207 0.859375 12.8027 0 11.2988 0H7.58789C6.08398 0 5.16602 0.859375 5.16602 2.27539V4.46289ZM0.732422 5.24414H18.1836C18.584 5.24414 18.9062 4.90234 18.9062 4.50195C18.9062 4.10156 18.584 3.76953 18.1836 3.76953H0.732422C0.341797 3.76953 0 4.10156 0 4.50195C0 4.91211 0.341797 5.24414 0.732422 5.24414ZM4.98047 21.748H13.9355C15.332 21.748 16.2695 20.8398 16.3379 19.4434L17.0215 5.05859H15.4492L14.7949 19.2773C14.7754 19.8633 14.3555 20.2734 13.7793 20.2734H5.11719C4.56055 20.2734 4.14062 19.8535 4.11133 19.2773L3.41797 5.05859H1.88477L2.57812 19.4531C2.64648 20.8496 3.56445 21.748 4.98047 21.748ZM6.5625 18.6035C6.93359 18.6035 7.17773 18.3691 7.16797 18.0273L6.86523 7.57812C6.85547 7.23633 6.61133 7.01172 6.25977 7.01172C5.88867 7.01172 5.64453 7.24609 5.6543 7.58789L5.94727 18.0273C5.95703 18.3789 6.20117 18.6035 6.5625 18.6035ZM9.45312 18.6035C9.82422 18.6035 10.0879 18.3691 10.0879 18.0273V7.58789C10.0879 7.24609 9.82422 7.01172 9.45312 7.01172C9.08203 7.01172 8.82812 7.24609 8.82812 7.58789V18.0273C8.82812 18.3691 9.08203 18.6035 9.45312 18.6035ZM12.3535 18.6035C12.7051 18.6035 12.9492 18.3789 12.959 18.0273L13.252 7.58789C13.2617 7.24609 13.0176 7.01172 12.6465 7.01172C12.2949 7.01172 12.0508 7.23633 12.041 7.58789L11.748 18.0273C11.7383 18.3691 11.9824 18.6035 12.3535 18.6035Z" fill="#8F9092" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2070_8907">
                                            <rect width="18.9062" height="23.4863" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="account__info">
                        <div className={`status${item.store.isBanned ? ' banned' : ''}`}>{item.store.isBanned ? 'Бан' : 'Активен'}</div>
                        <ul className="account__descr" style={{ 'minHeight': '238px' }}>
                            <li>
                                <span>ИНН:</span>
                                <span>{item.store.inn}</span>
                            </li>
                            <li>
                                <span>ОГРН:</span>
                                <span>{item.store.ogrn}</span>
                            </li>
                            {users}

                        </ul>
                        <div className="account__bottom">
                            <div className="d-flex flex-column">

                                <Link className="change" to={`/shops/partners/${item.store.id}`} data-popup="account-change">
                                    <span>РЕДАКТИРОВАТЬ ПАРТНЕРОВ</span>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.66482 14.9228L13.3191 4.27827L11.6101 2.55952L0.94607 13.2138L0.0183355 15.3818C-0.0695552 15.6161 0.174585 15.8896 0.40896 15.7919L2.66482 14.9228ZM14.1785 3.43843L15.1648 2.47163C15.6629 1.97358 15.6824 1.43647 15.2429 0.987257L14.9109 0.655226C14.4715 0.215773 13.9344 0.264601 13.4363 0.743117L12.45 1.71968L14.1785 3.43843Z" fill="#9DBDB0" />
                                    </svg>
                                </Link>

                                <Link className="change" to={`/shops/admins/${item.store.id}`} data-popup="account-change">
                                    <span>РЕДАКТИРОВАТЬ АДМИНИСТРАТОРОВ</span>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.66482 14.9228L13.3191 4.27827L11.6101 2.55952L0.94607 13.2138L0.0183355 15.3818C-0.0695552 15.6161 0.174585 15.8896 0.40896 15.7919L2.66482 14.9228ZM14.1785 3.43843L15.1648 2.47163C15.6629 1.97358 15.6824 1.43647 15.2429 0.987257L14.9109 0.655226C14.4715 0.215773 13.9344 0.264601 13.4363 0.743117L12.45 1.71968L14.1785 3.43843Z" fill="#9DBDB0" />
                                    </svg>
                                </Link>
                            </div>

                            {
                                item.store.isBanned ?
                                    <>
                                        <button className={`${item.store.isBanned ? 'ban' : ''}`} type="button" onClick={() => unban(item.store.id)}>
                                            <span>Активировать</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 511.985 511.985">
                                                <g>
                                                    <path d="M500.088 83.681c-15.841-15.862-41.564-15.852-57.426 0L184.205 342.148 69.332 227.276c-15.862-15.862-41.574-15.862-57.436 0-15.862 15.862-15.862 41.574 0 57.436l143.585 143.585c7.926 7.926 18.319 11.899 28.713 11.899 10.394 0 20.797-3.963 28.723-11.899l287.171-287.181c15.862-15.851 15.862-41.574 0-57.435z" fill="#8f9092" opacity="1"></path>
                                                </g>
                                            </svg>
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className={`${item.store.isBanned ? 'ban' : ''}`} type="button" onClick={() => ban(item.store.id)}>
                                            <span>Забанить</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 512 512">
                                                <g>
                                                    <path d="M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 474.718C135.4 474.718 37.282 376.6 37.282 256S135.4 37.282 256 37.282 474.718 135.4 474.718 256 376.6 474.718 256 474.718z" fill="#8f9092" opacity="1" ></path>
                                                    <path d="M79.392 406.842 406.23 80.003l26.357 26.357-326.84 326.84z" fill="#8f9092" opacity="1"></path>
                                                </g>
                                            </svg>
                                        </button>
                                    </>
                            }
                        </div>
                    </div>
                </li>
            )
        });

        return (
            <>
                {items}
            </>
        )
    }

    const AddButton = () => {
        return (
            <li className="account__add" >
                <button onClick={newShop}>
                    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1943_11105)">
                            <path d="M37.5 74.9635C58.0148 74.9635 75 57.9497 75 37.4817C75 16.977 57.9779 0 37.4632 0C16.9853 0 0 16.977 0 37.4817C0 57.9497 17.022 74.9635 37.5 74.9635ZM19.5588 37.5184C19.5588 35.6076 20.8823 34.2847 22.7941 34.2847H34.3014V22.783C34.3014 20.8722 35.5882 19.5125 37.4632 19.5125C39.375 19.5125 40.6983 20.8722 40.6983 22.783V34.2847H52.2428C54.1176 34.2847 55.4778 35.6076 55.4778 37.5184C55.4778 39.3927 54.1176 40.642 52.2428 40.642H40.6983V52.1805C40.6983 54.0912 39.375 55.4143 37.4632 55.4143C35.5882 55.4143 34.3014 54.0544 34.3014 52.1805V40.642H22.7941C20.8823 40.642 19.5588 39.3927 19.5588 37.5184Z" fill="#D9D9D9" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1943_11105">
                                <rect width="75" height="75" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </button>
            </li>
        )
    }

    const items = renderItems(storeList);

    const errorMessage = error ? <ErrorMessage /> : null;
    const skeleton = loading ? <Skeleton pageName={"ProfileList"} /> : null;

    return (
        <>
            <div className="container d-flex justify-content-between align-items-center">
                <ul className="breadcrumbs">
                    <li>
                        <h1 style={{ "padding": "20px 0", "margin": "-20px 0", "lineHeight": "normal" }}>Магазины</h1>
                    </li>
                </ul>
                {!error
                    ?
                    <div className="wrapper d-flex align-items-center" style={{ "gap": "20px" }}>
                        <button className="top__add" onClick={newShop}>+ Добавить</button>
                    </div>
                    :
                    null
                }
            </div>

            <div className="account">
                <div className="container">

                    {errorMessage}
                    {skeleton}
                    <ul className="account__body">
                        {!error ? items : null}
                        {!error ? <AddButton /> : null}
                    </ul>
                </div>
            </div >
            <Modal modalIsOpen={modalIsOpen} toggleModal={toggleModal} handleFormSubmit={handleFormSubmit} renderForm={renderForm} />
        </>
    )
}

export default ProfileListPage;