import { useCallback } from 'react';

const useLabelValue = () => {
    const getLabelValue = useCallback((dataList, productAttribute) => {
        const foundItem = dataList.find(item => item.id === productAttribute.uuid);

        return {
            label: foundItem ? foundItem.name : productAttribute.name,
            value: foundItem ? foundItem.id : null
        };
    }, []);

    return getLabelValue;
}

export default useLabelValue;
