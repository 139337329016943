import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import useBazarkenService from '../../services/BazarkenService';
import { Skeleton, ErrorMessage } from '../pages/index';
import Modal from './Modal';
import MoneyInfoWidget from './widgets/MoneyInfoWidget';

import './styles/shopListPage.scss';

const ShopListPage = () => {
    const [storeList, setStoreList] = useState([]);
    const { loading, error, getAdminAllStores, setWorkingStatus, clearError } = useBazarkenService();

    // const [selectedStore, setSelectedStore] = useState(null);

    // Следим за состоянием модального окна
    const [modalIsOpen, setModalIsOpen] = useState(false);
    // Переключаем модальное окно
    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen)
    };

    useEffect(() => {
        onRequest();
    }, []) // Если подставить пустой массив то функция вызовется только один раз

    const onRequest = () => {
        getAdminAllStores()
            .then(onStoreListLoaded)
    }

    const onStoreListLoaded = (newStoreList) => {
        const sortedList = [...newStoreList].sort((a, b) => a.name.localeCompare(b.name));
        setStoreList(storeList => sortedList);
    }

    const toggleWorkingStatus = (id, isWorksNow, event) => {
        const button = event.currentTarget;
        button.disabled = true;

        setWorkingStatus(id, !isWorksNow)
            .then(onRequest)
            .finally(() => {
                button.disabled = false;
            });
    }

    // const showMoneyInfo = (store) => {
    //     setSelectedStore(store)
    //     console.log(store)
    //     toggleModal()
    // }

    // const handleFormSubmit = (event) => {
    //     event.preventDefault()
    //     clearError()
    // }

    // const renderForm = () => {
    //     return (
    //         <>
    //             {selectedStore ?
    //                 <>
    //                     <h2 style={{ "marginBottom": "-10px" }}>{selectedStore.name}</h2>
    //                     <MoneyInfoWidget storeId={selectedStore.id} />
    //                     <div className="modal-footer" style={{ "gridTemplateColumns": "1fr" }}>
    //                         <button type="button" onClick={toggleModal}>Закрыть</button>
    //                     </div>
    //                 </>
    //                 : null
    //             }
    //         </>
    //     )
    // }

    function renderItems(arr) {
        const items = arr.map((item, i) => {
            return (
                <li key={item.id}>
                    <div className={'shops-list__item empty'} item-count={0}>

                        <Link className="shops-list__link" to={`/shops/${item.id}`}>{item.name}</Link>

                        <div className={`status${item.isWorksNow ? ' active' : ''}`}>
                            {item.isWorksNow ?
                                <>
                                    <span>Открыто</span>

                                    <button type="button"
                                        onClick={(event) => toggleWorkingStatus(item.id, item.isWorksNow, event)}>Закрыть<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 512 512"><g><path d="M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 474.718C135.4 474.718 37.282 376.6 37.282 256S135.4 37.282 256 37.282 474.718 135.4 474.718 256 376.6 474.718 256 474.718z" fill="#8f9092" opacity="1"></path><path d="M79.392 406.842 406.23 80.003l26.357 26.357-326.84 326.84z" fill="#8f9092" opacity="1"></path></g></svg></button>
                                </>
                                :
                                <>
                                    <span>Закрыто</span>

                                    <button type="button"
                                        onClick={(event) => toggleWorkingStatus(item.id, item.isWorksNow, event)}>Открыть<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 512 512"><g><path d="M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 474.718C135.4 474.718 37.282 376.6 37.282 256S135.4 37.282 256 37.282 474.718 135.4 474.718 256 376.6 474.718 256 474.718z" fill="#8f9092" opacity="1"></path></g></svg></button>
                                </>
                            }

                        </div>

                        {/* <button onClick={() => showMoneyInfo(item)} type="button" className="moneyInfo">
                            Доход <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 500 500">
                                <g>
                                    <path d="M310.2 198.13c0 15.59-3 25.47-9.5 30.41-6.08 4.57-18.63 6.85-37.26 6.85h-39.17v-74.15h39.17c18.63 0 30.8 2.29 37.26 7.23 6.46 4.53 9.5 14.45 9.5 29.66z" fill="#9dbdb0" opacity="1"></path>
                                    <path d="M0 250c0 138.07 111.93 250 250 250s250-111.93 250-250S388.07 0 250 0 0 111.93 0 250zm357 20.37c-18.25 13.69-43 17.49-85.93 17.49h-46.8v23.95h66.54c4.19 0 6.09 1.91 6.09 5.71v27c0 3.81-1.9 5.71-6.09 5.71h-66.54v43.72c0 4.95-1.14 6.09-5.7 6.09h-58.94c-4.56 0-5.7-1.14-5.7-6.09v-43.73H125c-4.18 0-6.08-1.9-6.08-5.71v-27c0-3.8 1.9-5.71 6.08-5.71h28.9v-38.4H125c-4.18 0-6.08-1.9-6.08-5.7v-26.61c0-3.8 1.9-5.7 6.08-5.7h28.9V109.91c0-3.42 1.9-4.94 5.7-5.32 37.27-3 74.53-4.56 111.41-4.56 41.83 0 70.72 6.84 86.31 20.15 16 13.31 24 38.78 24 76-.01 38.07-6.1 60.5-24.32 74.19z" fill="#9dbdb0" opacity="1"></path>
                                </g>
                            </svg>
                        </button> */}

                        <div className="shops-list__bottom">

                            <Link className="shops-list__edit" to={`/orders/${item.id}`}>
                                <span>СПИСОК ЗАКАЗОВ</span>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="16" height="16" x="0" y="0" viewBox="0 0 448 448" style={{ 'enableBackground': 'new 0 0 512 512', 'opacity': '1' }}>
                                    <g>
                                        <path d="M383.921 143.04c-.508-8.46-7.525-15.055-16-15.04h-64V80c0-44.183-35.817-80-80-80s-80 35.817-80 80v48h-64c-8.475-.015-15.492 6.58-16 15.04l-14.08 254.24c-1.502 26.467 18.736 49.141 45.203 50.643a47.43 47.43 0 0 0 2.797.077h252.32c26.51.043 48.034-21.413 48.077-47.923a47.43 47.43 0 0 0-.077-2.797zm-208-63.04c0-26.51 21.49-48 48-48s48 21.49 48 48v48h-96z" fill="#9dbdb0">
                                        </path>
                                    </g>
                                </svg>
                            </Link>

                            <Link className="shops-list__edit" to={`/shops/${item.id}`}>
                                <span>РЕДАКТИРОВАТЬ КАТАЛОГ</span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1912_10946)">
                                        <path d="M2.66482 14.9228L13.3191 4.27827L11.6101 2.55952L0.94607 13.2138L0.0183355 15.3818C-0.0695552 15.6161 0.174585 15.8896 0.40896 15.7919L2.66482 14.9228ZM14.1785 3.43843L15.1648 2.47163C15.6629 1.97358 15.6824 1.43647 15.2429 0.987257L14.9109 0.655226C14.4715 0.215773 13.9344 0.264601 13.4363 0.743117L12.45 1.71968L14.1785 3.43843Z" fill="#9DBDB0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1912_10946">
                                            <rect width="15.5564" height="15.8121" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Link>
                        </div>
                    </div>
                </li>
            )
        });
        // А эта конструкция вынесена для центровки спиннера/ошибки
        return (
            <ul className="shops-list__body">
                {items}
            </ul>
        )
    }

    const items = renderItems(storeList);
    const errorMessage = error ? <ErrorMessage /> : null;
    const skeleton = (loading && storeList.length === 0) ? <Skeleton pageName={"ShopList"} /> : null;

    return (
        <>
            <div className="shops-list">
                <div className="container">
                    <h1>Магазины</h1>
                    {errorMessage}
                    {skeleton}
                    {items}
                </div>
            </div>
            {/* <Modal modalIsOpen={modalIsOpen} toggleModal={toggleModal} renderForm={renderForm} handleFormSubmit={handleFormSubmit} /> */}
        </>
    )
}

export default ShopListPage;
