import { Link, NavLink, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import logo from '../../resources/img/icons/header-logo.svg';
import './appHeader.scss';

const AppHeader = ({ onLogout }) => {
    const [cookies, removeCookie] = useCookies(['user']);
    const location = useLocation();

    const handleLogout = () => {
        removeCookie('user');
        onLogout();
    };

    return (
        <header className="header">
            <div className="container">
                <div className="header__body"> <Link className="header__logo" to={cookies.user ? '/shops' : '/login'}>
                    <img src={logo} alt="" /></Link>

                    {location.pathname !== '/login' && <Navigation onLogout={handleLogout} cookies={cookies} />}
                </div>
            </div>
        </header>
    )
}

const Navigation = ({ onLogout, cookies }) => {
    if (!cookies.user || cookies.user === "undefined") {
        return false;
    } else {

        // Пользователи
        const MyLink = ({ url, name }) => {
            return (
                <li>
                    <NavLink
                        className={({ isActive }) => (isActive ? 'active' : null)}
                        to={url}>{name}</NavLink>
                </li>
            )
        }

        return (
            <>
                <nav>
                    <ul>
                        {/* Общие ссылки */}
                        {<MyLink url="/shops" name="Магазины" />}

                        {/* Партнер */}
                        {cookies.user.user.roles.includes("partner") ? <MyLink url="/review" name="Товары на рассмотрении" /> : null}

                        {/* Суперадмин */}
                        {cookies.user.user.roles.includes("superuser") ? <MyLink url="/users" name="Пользователи" /> : null}
                        {cookies.user.user.roles.includes("superuser") ? <MyLink url="/manufacturers" name="Производители" /> : null}
                        {cookies.user.user.roles.includes("superuser") ? <MyLink url="/measurements" name="Измерения" /> : null}
                        {cookies.user.user.roles.includes("superuser") ? <MyLink url="/components" name="Компоненты" /> : null}
                        {cookies.user.user.roles.includes("superuser") ? <MyLink url="/category" name="Категории" /> : null}
                        {cookies.user.user.roles.includes("superuser") ? <MyLink url="/products" name="Товары" /> : null}
                    </ul>
                </nav>

                <div className="header__user">
                    <div className="user-info">
                        <span>({cookies.user.user.firstname ? cookies.user.user.firstname : null} {cookies.user.user.lastname ? cookies.user.user.lastname.substring(0, 1) + '.' : null})&nbsp;</span>
                        <button className="btn-text" onClick={onLogout}>Выйти</button>
                    </div>

                    <small>{cookies.user ? cookies.user.user.id : null}</small>
                </div>

                <div className="burger"> <span className="first"></span><span className="second"></span><span className="third"></span></div>
            </>
        )
    }

}

export default AppHeader;
