import { useState } from 'react';
import { Link } from 'react-router-dom';

import useBazarkenService from '../../services/BazarkenService';

import './styles/loginPage.scss';
import logo from '../../resources/img/icons/logo.svg'

const LoginPage = ({ onLogin }) => {
    const [formState, setFormState] = useState({ login: "", password: ""});

    const { error, login, clearError } = useBazarkenService();

    const handleSubmit = (e) => {
        e.preventDefault();

        clearError();
        login(formState.login, formState.password)
            .then(onLogin)
    };

    const handleInputChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className="login-page">
            <div className="login-page__body">

                <div className="login-page__top">
                    <img src={logo} alt="" />
                    <h2>Вход</h2>
                </div>

                <form className="login-page__form" onSubmit={handleSubmit}>

                    <div className="form-item login">
                        <label htmlFor="login">Телефон</label>

                        <input id="login" type="text" name="login" value={formState.login} onChange={handleInputChange} />

                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1672_8570)">
                                <path d="M1.66992 17.8809H14.7656C15.8105 17.8809 16.4355 17.3926 16.4355 16.582C16.4355 14.0625 13.2812 10.5859 8.21289 10.5859C3.1543 10.5859 0 14.0625 0 16.582C0 17.3926 0.625 17.8809 1.66992 17.8809ZM8.22266 8.83789C10.3125 8.83789 12.1289 6.96289 12.1289 4.52148C12.1289 2.10938 10.3125 0.322266 8.22266 0.322266C6.13281 0.322266 4.31641 2.14844 4.31641 4.54102C4.31641 6.96289 6.12305 8.83789 8.22266 8.83789Z" fill="black" fillOpacity="0.85" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1672_8570">
                                    <rect width="16.4355" height="17.8809" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div className="form-item password">
                        <label htmlFor="password">Пароль</label>

                        <input id="password" type="password" name="password" value={formState.password} onChange={handleInputChange} />

                        <svg style={{"margin": "0 1px 0 2px"}} width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1672_8578)">
                                <path d="M2.19727 19.1602H11.1328C12.5684 19.1602 13.3301 18.3789 13.3301 16.8359V10.1074C13.3301 8.56445 12.5684 7.79297 11.1328 7.79297H2.19727C0.761719 7.79297 0 8.56445 0 10.1074V16.8359C0 18.3789 0.761719 19.1602 2.19727 19.1602ZM1.70898 8.54492H3.26172V5.24414C3.26172 2.7832 4.83398 1.47461 6.66016 1.47461C8.48633 1.47461 10.0781 2.7832 10.0781 5.24414V8.54492H11.6211V5.45898C11.6211 1.78711 9.21875 0 6.66016 0C4.11133 0 1.70898 1.78711 1.70898 5.45898V8.54492Z" fill="black" fillOpacity="0.85" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1672_8578">
                                    <rect width="13.3301" height="19.6777" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div className={`error-message`} style={error ? { 'display': 'block' } : { 'display': 'none' }}>
                        <small>Вы ввели неверный номер телефона и пароль</small>
                    </div>

                    <div className="form-item wrapper">
                        <div className="form-item submit">
                            <input type="submit" value="Вход" />
                        </div>
                        <div className="form-item forget">
                            <Link to={`/reg`}>Регистрация</Link>
                        </div>
                    </div>
                </form>
            </div >
        </div >
    )
}

export default LoginPage;