import { useParams, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import useBazarkenService from '../../../services/BazarkenService';
import Modal from '../Modal';
import { Skeleton, ErrorMessage } from '../../pages/index';

const CategoryListPage = () => {

    // Следим за состоянием модального окна
    const [modalIsOpen, setModalIsOpen] = useState(false);
    // Переключаем модальное окно
    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen)
    };

    const [items, setItems] = useState(null);
    const { loading, error, clearError, getAllCategories, addCategory } = useBazarkenService();

    useEffect(() => {
        updateItems()
    }, [])

    const onStoreLoaded = (items) => {
        // console.log(items)
        setItems(items);
    }

    const updateItems = () => {
        clearError()

        getAllCategories()
            .then(onStoreLoaded)
    }

    // const deleteMeasurement = (id) => {
    //     if (window.confirm("Вы действительно хотите удалить производителя?")) {
    //         // clearError()
    //         removeMeasurement(id)
    //             .then(updateItems)
    //     }
    // }

    const newCategory = async (name) => {
        clearError()

        return await addCategory(name)
            .then(updateItems)
            .then(toggleModal)
    }

    const handleFormSubmit = (event) => {
        // Предотвращаем обновление страницы
        event.preventDefault();
        // Получаем значение из поля ввода
        const name = event.target.elements.name.value;
        // Вызываем функцию newCategory с полученным значением
        newCategory(name)
            .then(() => {
                // Получаем все элементы input
                let inputs = document.getElementsByTagName('input');

                // Проходимся по каждому элементу input
                for (let i = 0; i < inputs.length; i++) {
                    // Если тип поля text, email, password, etc., то очищаем его
                    if (inputs[i].type === 'text' || inputs[i].type === 'password' || inputs[i].type === 'email') {
                        inputs[i].value = ''; // Устанавливаем значение в пустую строку
                    }
                }

            });
    }

    function renderForm() {
        return (
            <>
                <div className="form-item">
                    <label>Название</label>
                    <input name="name" type="text" defaultValue="" placeholder='' />
                </div>

                <div className="form-item submit">
                    <button type="submit">Сохранить</button>
                </div>
            </>
        )
    }

    const content = !(!items) && !error ? (<View result={items} toggleModal={toggleModal} />) : null;

    const errorMessage = error ? <ErrorMessage /> : null;
    const skeleton = loading ? <Skeleton pageName={"AdminList"} /> : null;

    return (
        <>
            <div className="container d-flex justify-content-between align-items-center">
                <ul className="breadcrumbs">
                    <li>
                        <h1 style={{ "padding": "20px 0", "margin": "-20px 0", "lineHeight": "normal" }}>Категории</h1>
                    </li>
                </ul>
                {!error
                    ?
                    <div className="wrapper d-flex align-items-center" style={{ "gap": "20px" }}>
                        <button className="top__add" onClick={toggleModal}>+ Добавить</button>
                    </div>
                    :
                    null
                }
            </div>
            <div className="contacts">
                <div className="container">
                    {errorMessage}

                    <ul className="contacts__body">
                        {skeleton}
                        {content}
                    </ul>

                </div>
            </div>
            <Modal modalIsOpen={modalIsOpen} toggleModal={toggleModal} handleFormSubmit={handleFormSubmit} renderForm={renderForm} />
        </>
    )

}

const View = ({ result, toggleModal }) => {

    const items = result.items.map((item, i) => {
        return (
            <li key={item.id} className="contacts__item">
                <ul>
                    <li>
                        <span>ID:</span>
                        <span>{item.id}</span>
                    </li>
                    <li>
                        <span>Название:</span>
                        <span>{item.name}</span>
                    </li>
                </ul>
            </li >
        )
    });

    return (
        <>
            {items}

            <li className="contacts__add" onClick={toggleModal}>
                <button>
                    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.5 75.9635C58.0148 75.9635 75 58.9497 75 38.4817C75 17.977 57.9779 1 37.4632 1C16.9853 1 0 17.977 0 38.4817C0 58.9497 17.022 75.9635 37.5 75.9635ZM19.5588 38.5184C19.5588 36.6076 20.8823 35.2847 22.7941 35.2847H34.3014V23.783C34.3014 21.8722 35.5882 20.5125 37.4632 20.5125C39.375 20.5125 40.6983 21.8722 40.6983 23.783V35.2847H52.2428C54.1176 35.2847 55.4778 36.6076 55.4778 38.5184C55.4778 40.3927 54.1176 41.642 52.2428 41.642H40.6983V53.1805C40.6983 55.0912 39.375 56.4143 37.4632 56.4143C35.5882 56.4143 34.3014 55.0544 34.3014 53.1805V41.642H22.7941C20.8823 41.642 19.5588 40.3927 19.5588 38.5184Z" fill="#D9D9D9" />
                    </svg>
                </button>
            </li>
        </>
    )
}

export default CategoryListPage;