import { useState, useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';


import useBazarkenService from '../../services/BazarkenService';

import AppHeader from '../appHeader/AppHeader';

import { LoginPage, ProfileListPage, ShopCatalogPage, ProfileContactListPage, UserListPage, ManufacturersListPage, MeasurementsListPage, ProductListPage, RegPage, MePage, ShopListPage, ProductComponentsPage, ReviewsPartnerPage, OrderListPage, CategoryListPage } from '../pages/index';

function AppBox() {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const location = useLocation();

    // Setting a cookie
    const [user, setUser] = useState(cookies.user ? cookies.user.token : null);
    const navigate = useNavigate();
    const { error } = useBazarkenService();

    useEffect(() => {
        if (user === null && location.pathname !== '/reg') {
            navigate('/login');
        }
        else if (user && user !== "undefined") {
            if (cookies.user.user.shouldUpdatePassword) {
                navigate('/me');
            } else if (location.pathname === '/reg' || location.pathname === '/login' || location.pathname === '/me') {
                navigate('/shops');
            }
        }
    }, [user, navigate, cookies]);

    const handleLogin = (user) => {
        if (!error) {
            setUser(user);
            setCookie('user', JSON.stringify(user), { path: '/' });
            navigate('/shops');
        } else {
            setUser(null);
        }
    }

    const handleLogout = () => {
        removeCookie('user');
        setUser(null);
    };

    return (
        <>
            <AppHeader onLogout={handleLogout} />
            <main>
                <Routes>

                    <Route path='/login' element={<LoginPage onLogin={handleLogin} />} />
                    <Route path='/reg' element={<RegPage onLogin={handleLogin} />} />


                    <Route path="/">
                        <Route index element={<Navigate to="/shops" />} />
                        {/* <Route path="shops" element={<ShopListPage />} /> */}
                        <Route path="shops/:storeId" element={<ProductListPage />} />
                        <Route path="orders/:storeId" element={<OrderListPage />} />
                        <Route path="shops" element={cookies?.user?.user?.roles?.includes('superuser') ? <ProfileListPage /> : <ShopListPage />} />
                        {/* <Route path="shops/:storeId" element={<ShopCatalogPage />} /> */}
                        <Route path="shops/partners/:storeId" element={<ProfileContactListPage />} />
                        <Route path="shops/admins/:storeId" element={<ProfileContactListPage />} />
                    </Route>

                    <Route path="/me" element={<MePage />} />

                    <Route path="/users" element={<UserListPage />} />

                    <Route path="/manufacturers" element={<ManufacturersListPage />} />
                    <Route path="/measurements" element={<MeasurementsListPage />} />
                    <Route path="/products" element={<ProductListPage />} />
                    <Route path="/components" element={<ProductComponentsPage />} />

                    <Route path="/review" element={<ReviewsPartnerPage />} />

                    <Route path="/category" element={<CategoryListPage />} />

                    {/* <Route path="/profiles" element={<ProfileListPage />} /> */}
                    {/* <Route path="/profiles/:storeId" element={<ProfileContactListPage />} /> */}

                    {/* <Route path="/profiles">
                        <Route index element={<ProfileListPage />} />
                        <Route path=":shopId" element={<ProfileContactListPage />} />
                    </Route> */}

                    <Route path="*" element={<h1 style={{ 'textAlign': 'center', 'padding': '100px 0' }}>Страницы не существует</h1>} />

                </Routes>
            </main>
        </>
    );
}

function App() {

    return (
        <Router>
            <AppBox />
        </Router>
    )
}

export default App;
