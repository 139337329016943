import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';

import useBazarkenService from '../../services/BazarkenService';
import useLabelValue from "../../hooks/getLabelValue";

import Modal from './Modal';
import { Skeleton, ErrorMessage } from '../pages/index';

import imgIcon from '../../resources/img/icons/img.svg';

const ReviewsPartnerPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [commentedProducts, setCommentedProducts] = useState([]);
    const [waitForReviewProducts, setWaitForReviewProducts] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const [tab, setTab] = useState("main");
    const [clickedProduct, setClickedProduct] = useState(null);
    const [measurements, setMeasurements] = useState([]);
    const [components, setComponents] = useState([]);
    const [ingridients, setIngridients] = useState([]);
    const [gramm, setGramm] = useState([]);
    const [currentAction, setCurrentAction] = useState(null);
    const [selectedManufacturer, setSelectedManufacturer] = useState(null);
    const [selectedMeasurement, setSelectedMeasurement] = useState(null);
    const [selectedExpMeasurement, setSelectedExpMeasurement] = useState(null);
    const [selectedIngridients, setSelectedIngridients] = useState([]);

    const getLabelValue = useLabelValue();


    // Следим за состоянием модального окна
    const [modalIsOpen, setModalIsOpen] = useState(false);
    // Переключаем модальное окно
    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen)
    };

    const { loading, error, clearError, getMyReviewProducts, getManufacturers, getMeasurements, getComponents, editProductToReview } = useBazarkenService();

    useEffect(() => {
        updateList()
    }, [])

    useEffect(() => {
        if (commentedProducts.length > 0) {
            if (location.state?.fromAddReviewProduct) {
                setTab("main");
                // Удаляем fromAddReviewProduct из состояния
                const { fromAddReviewProduct, ...newState } = location.state;
                navigate(location.pathname, { state: newState, replace: true });
            } else {
                setTab("commented");
            }
        } else {
            setTab("main");
        }
    }, [commentedProducts]);

    const onListLoaded = (products) => {
        const commentedProducts = products.filter(product => product.status === "commented");
        const waitForReviewProducts = products.filter(product => product.status === "waitForReview");

        setCommentedProducts(commentedProducts);
        setWaitForReviewProducts(waitForReviewProducts);
    }

    const updateList = () => {
        clearError()

        getMyReviewProducts()
            .then(onListLoaded)

        getManufacturers()
            .then(res => {
                setManufacturers(res.items)
            })

        getMeasurements()
            .then(res => {

                const gramm = res.items.find(item => (item.name.toLowerCase() === 'гр' || item.name.toLowerCase() === 'гр.' || item.name.toLowerCase() === 'грамм'));

                setMeasurements(res.items)
                setGramm(gramm)

            })

        getComponents()
            .then(res => {
                setIngridients(res.items);
                const newComponents = res.items.reduce((acc, item) => {
                    acc[item.name.toLowerCase()] = item.id;
                    return acc;
                }, {});

                setComponents(newComponents);
            })
    }

    const openMainTab = () => {
        setTab("main");
    }

    const openCommentedTab = () => {
        setTab("commented");
    }

    const openProductDetails = (product, measurements) => {
        setClickedProduct(product);
        setSelectedMeasurement(product.measurement)
        setSelectedManufacturer(product.manufacturer)
        setSelectedExpMeasurement(() => {

            const isExpDate = (component) => {
                if (!component.perVolume && !!component.amount) {
                    return true;
                } else {
                    return false;
                }
            };

            let type = { uuid: null, name: null };

            product.components.forEach(component => {
                if (isExpDate(component)) {  // Если компонент — срок хранения
                    type = component.type;
                }
            });

            return type;
        })
        setSelectedIngridients(() => {
            const isComposition = (component) => !component.amount;

            return product.components
                .filter(isComposition)   // Фильтруем компоненты, которые прошли проверку
                .map(component => {
                    return { 'label': component.componentName ? component.componentName : findComponentName(component.componentId), 'value': component.componentId }
                });  // Создаём массив только с типами компонентов
        })
        setCurrentAction('details')
        toggleModal()
    }

    const statusName = (status) => {

        switch (status) {
            case "waitForReview":
                return <span className='text-danger'>Ожидает проверки</span>;
            case "commented":
                return <span className='text-success'>Откомментирован</span>;
            default:
                return <span className='text-light'>Неизвестен</span>;
        }
    }

    const findManufacturerName = (uuid) => {
        const foundManufacturer = manufacturers.find(manufacturer => manufacturer.id === uuid);
        return foundManufacturer ? foundManufacturer.name : 'Загрузка...';
    }

    const findMeasurementName = (uuid) => {
        if (!uuid) {
            return 'ГР';
        }
        const foundMeasurement = measurements.find(measurement => measurement.id === uuid);
        return foundMeasurement ? foundMeasurement.name : 'Загрузка...';
    }

    const findComponentName = (uuid) => {
        const foundComponent = ingridients.find(component => component.id === uuid);
        return foundComponent ? foundComponent.name : 'Загрузка...';
    }

    const renderComponentInfo = (component, index) => {
        if (component.type.name || component.type.uuid) {
            return (
                <p key={index} style={{ 'marginTop': '-10px' }}>
                    <span className='text-light'>
                        {component.componentName ? component.componentName : findComponentName(component.componentId)}
                    </span>

                    {!!component.perVolume ?
                        <span className="text-light">
                            ({' ' + component.perVolume} {component.type.name ? component.type.name : findMeasurementName(component.type.uuid)})
                        </span>
                        : null
                    }
                    {<span className='text-light'>: </span>}
                    {component.amount}
                </p >
            );
        }
        return null;
    }

    const renderIngridients = (component, index, length) => {
        if (!component.type.name && !component.type.uuid) {
            return (
                <span key={index}>
                    {component.componentName ? component.componentName : findComponentName(component.componentId)}
                    {index !== length - 1 ? ', ' : '.'}
                </span>
            );
        }
        return null;
    }

    const handleFormSubmit = (event) => {
        event.preventDefault()
        clearError()

        // Стандартные свойства объекта
        // Данные о товаре
        const name = event.target.elements.name.value;
        const imageURL = event.target.elements.imageURL.value;
        const size = event.target.elements.size.value;
        const subtitle = event.target.elements.subtitle.value;
        const article = event.target.elements.article.value;
        const description = event.target.elements.description.value;

        // Компоненты
        // КБЖУ на 100 г.
        const kcal = event.target.elements.kcal.value;
        const protein = event.target.elements.protein.value;
        const fat = event.target.elements.fat.value;
        const carbos = event.target.elements.carbos.value;

        // Срок хранения
        const exp = event.target.elements.exp.value;

        const type = gramm ? {
            "uuid": gramm.id
        } : {
            "name": "гр"
        };

        console.log(selectedExpMeasurement)

        const productComponents = [
            {
                type,
                "perVolume": 100,
                "amount": parseInt(kcal),
                ...(components['ккал'] ? {
                    "componentId": components['ккал'],
                } : {
                    "componentName": 'ККАЛ',
                }),
            },

            {
                type,
                "perVolume": 100,
                "amount": parseInt(protein),
                ...(components['белки'] ? {
                    "componentId": components['белки'],
                } : {
                    "componentName": 'Белки',
                }),
            },
            {
                type,
                "perVolume": 100,
                "amount": parseInt(fat),
                ...(components['жиры'] ? {
                    "componentId": components['жиры'],
                } : {
                    "componentName": 'Жиры',
                }),
            },
            {
                type,
                "perVolume": 100,
                "amount": parseInt(carbos),
                ...(components['углеводы'] ? {
                    "componentId": components['углеводы'],
                } : {
                    "componentName": 'Углеводы',
                }),
            },
            {
                "amount": parseInt(exp),
                ...(components['срок хранения'] ? {
                    "componentId": components['срок хранения'],
                } : {
                    "componentName": 'Срок хранения',
                }),
                ...selectedExpMeasurement.uuid ? {
                    "type": { "uuid": selectedExpMeasurement.uuid },
                } : {
                    "type": { "name": selectedExpMeasurement.name },
                },
            },
        ];

        const productIngridients = selectedIngridients.map((item) => {
            return {
                ...item.value ? {
                    "componentId": item.value,
                } : {
                    "componentName": item.label,
                },
            }
        });

        const allComponents = [...productComponents, ...productIngridients];

        editProductToReview(selectedMeasurement, selectedManufacturer, name, description, imageURL, subtitle, article, size, allComponents, clickedProduct.id)
            .then(toggleModal)
            .then(updateList)
            .then(() => navigate('/review', { state: { fromAddReviewProduct: true } }))
    }

    const renderForm = () => {

        if (clickedProduct) {
            if (currentAction === 'details') {
                return (
                    <>
                        {/* Изображение */}
                        <a href={clickedProduct.imageURL} target="_blank" rel="noopener noreferrer">
                            <picture style={{ 'position': 'absolute', 'top': '38px', 'right': '80px' }}>
                                <img
                                    src={clickedProduct.imageURL}
                                    alt={'Фото ' + clickedProduct.name}
                                    style={{ "height": '150px', 'width': '150px', 'borderRadius': "100%", "objectFit": "cover", "border": "1px solid #8F9092", 'cursor': 'pointer' }} />
                            </picture>
                        </a>
                        {/* Название */}
                        <h2>{clickedProduct.name}</h2>

                        {/* Статус */}
                        {clickedProduct.status ?
                            <p style={{ 'marginTop': '-20px' }}>{statusName(clickedProduct.status)}</p>
                            : null
                        }

                        {/* Подпись */}
                        <p style={{ 'marginTop': '-20px' }}>{clickedProduct.subtitle}</p>
                        <p>
                            <span className='text-light'>Артикул:</span> {clickedProduct.article}
                        </p>

                        {/* Производитель */}
                        <p style={{ 'marginTop': '-10px' }}>
                            <span className='text-light'>Производитель:</span> {typeof (clickedProduct.manufacturer) === "object" ? (clickedProduct.manufacturer.name ? clickedProduct.manufacturer.name : findManufacturerName(clickedProduct.manufacturer.uuid)) : clickedProduct.manufacturer}
                        </p>

                        {/* Размер/Объем/Кол-во	 */}
                        <p style={{ 'marginTop': '-10px' }}>
                            <span className='text-light'>Размер/Объем/Кол-во:</span> {clickedProduct.size} {typeof (clickedProduct.measurement) === "object" ? (clickedProduct.measurement.name ? clickedProduct.measurement.name : findMeasurementName(clickedProduct.measurement.uuid)) : clickedProduct.measurement}
                        </p>

                        {/* Описание */}
                        <p style={{ 'marginTop': '-10px' }}>
                            <span className='text-light'>Описание:</span> {clickedProduct.description}
                        </p>

                        {/* Компоненты */}
                        {clickedProduct.components.length > 0 && (clickedProduct.components.map((component, index) => (
                            renderComponentInfo(component, index)
                        )))}

                        {/* Состав */}
                        <p style={{ 'marginTop': '-10px' }}>
                            <span className="text-light">Состав: </span>
                            {
                                clickedProduct.components.length > 0 && (clickedProduct.components.map((component, index) => (
                                    renderIngridients(component, index, clickedProduct.components.length)
                                )))}
                        </p >

                        {/* Комментарии */}
                        {(!!clickedProduct.comments && clickedProduct.comments.length > 0) ?
                            <>
                                <p className="text-light">Комментарии:</p>
                                {clickedProduct.comments.map((comment, index) => (
                                    <p key={index} style={{ 'marginTop': '-20px' }}>{comment.comment}</p>
                                ))}

                                <div className="modal-footer">
                                    <button type="button" onClick={() => { toggleModal() }}>Закрыть</button>
                                    <button id='asdasd' type="button"
                                        onClick={() => {
                                            setCurrentAction('edit')
                                        }}
                                        className='approve-modal'>Изменить и проверить</button>
                                </div>
                            </>
                            :
                            <div className="modal-footer" style={{ 'gridTemplateColumns': '1fr' }}>
                                <button type="button" onClick={() => { toggleModal() }}>Закрыть</button>
                            </div>}




                    </>
                )
            } else {
                const manufacturersOptions = manufacturers.map((item) => {
                    return { value: item.id, label: item.name }
                })

                const measurementsOptions = measurements.map((item) => {
                    return { value: item.id, label: item.name }
                })

                const componentsOptions = ingridients
                    .filter(item =>
                        item.name.toLowerCase() !== "срок хранения" &&
                        item.name.toLowerCase() !== "ккал" &&
                        item.name.toLowerCase() !== "белки" &&
                        item.name.toLowerCase() !== "жиры" &&
                        item.name.toLowerCase() !== "углеводы"
                    )
                    .map((item) => {
                        return { value: item.id, label: item.name }
                    })

                const handleManufacturerChange = (selectedOption) => {
                    if (selectedOption.__isNew__) {
                        // Пользователь создал новый элемент.
                        setSelectedManufacturer(
                            {
                                "uuid": null,
                                "name": selectedOption.label
                            }
                        );
                    } else {
                        // Это существующий элемент.
                        setSelectedManufacturer(
                            {
                                "uuid": selectedOption.value,
                                "name": null
                            }
                        );
                    }
                };

                const handleMeasurementChange = (selectedOption) => {
                    if (selectedOption.__isNew__) {
                        // Пользователь создал новый элемент.
                        setSelectedMeasurement(
                            {
                                "uuid": null,
                                "name": selectedOption.label
                            }
                        );
                    } else {
                        // Это существующий элемент.
                        setSelectedMeasurement(
                            {
                                "uuid": selectedOption.value,
                                "name": null
                            }
                        );
                    }
                };

                const handleExpMeasurementChange = (selectedOption) => {
                    if (selectedOption.__isNew__) {
                        // Пользователь создал новый элемент.
                        setSelectedExpMeasurement(
                            {
                                "uuid": null,
                                "name": selectedOption.label
                            }
                        );
                    } else {
                        // Это существующий элемент.
                        setSelectedExpMeasurement(
                            {
                                "uuid": selectedOption.value,
                                "name": null
                            }
                        );
                    }
                };

                const handleIngridientsChange = (selectedOptions) => {
                    if (selectedOptions) {
                        let updatedIngridients = [];
                        // Если выбран хотя бы один элемент
                        selectedOptions.forEach(option => {
                            if (option.__isNew__) {
                                updatedIngridients.push({ 'label': option.label, 'value': null });
                            } else {
                                updatedIngridients.push({ 'label': option.label, 'value': option.value });
                            }
                        });

                        setSelectedIngridients(updatedIngridients);
                    } else {
                        setSelectedIngridients([])
                    }
                };

                const validateImageUrl = (url) => {
                    return new Promise((resolve, reject) => {
                        var img = new Image();
                        img.onload = () => resolve(true);
                        img.onerror = () => reject(false);
                        img.src = url;
                    });
                }

                const handleImageUpdate = async () => {
                    const imageURL = document.getElementById('productImage').value;
                    try {
                        await validateImageUrl(imageURL);
                        // Если URL действителен, обновляем превью изображения
                        document.querySelector('.uploadImg picture img').src = imageURL;
                        document.querySelector('.uploadImg .error-message').classList.add('d-none');
                    } catch (error) {
                        document.querySelector('.uploadImg picture img').src = imgIcon;
                        // Если URL не действителен, выводим сообщение об ошибке
                        document.querySelector('.uploadImg .error-message').classList.remove('d-none');
                        // alert("Пожалуйста, введите действительную ссылку на изображение.");
                    }
                }

                const resizeTextarea = (event) => {
                    event.target.style.height = "auto";
                    event.target.style.height = `${event.target.scrollHeight}px`;
                }

                const clickedProductManufacturer = () => getLabelValue(manufacturers, clickedProduct.manufacturer);
                const clickedProductMeasurement = () => getLabelValue(measurements, clickedProduct.measurement);

                const productData = {
                    kcal: null,
                    protein: null,
                    carbs: null,
                    fat: null,
                    expDate: null
                };

                const composition = [];

                // Функция для получения имени ингредиента по ID
                const getIngredientByName = (componentId) => {
                    const foundIngredient = ingridients.find(ing => ing.id === componentId);
                    return foundIngredient ? foundIngredient.name : null;
                };

                // Функция для получения единицы измерения по UUID
                const getMeasurementByName = (uuid) => {
                    const foundMeasurement = measurements.find(m => m.id === uuid);
                    return foundMeasurement ? foundMeasurement.name : null;
                };

                // Проверка, является ли компонент составом продукта
                const isComposition = (component) => !component.amount;
                // Проверка, является ли компонент сроком хранения
                const isExpDate = (component) => {
                    if (!component.perVolume && !!component.amount) {
                        return true;
                    } else {
                        return false;
                    }
                };
                // Проверка, является ли компонент питательным веществом (ккал, белки и т.д.)
                const isNutrient = (component) => !!component.perVolume && !!component.amount;

                // Сопоставление имён ингредиентов с ключами productData
                const nutrientMapping = {
                    "ккал": "kcal",
                    "белки": "protein",
                    "углеводы": "carbs",
                    "жиры": "fat"
                };

                // Обработка компонентов продукта
                clickedProduct.components.forEach(component => {
                    if (isComposition(component)) {  // Если компонент — состав
                        const ingredientName = getIngredientByName(component.componentId);
                        if (ingredientName) {
                            composition.push({ label: ingredientName, value: component.componentId });
                        }
                    } else if (isExpDate(component)) {  // Если компонент — срок хранения
                        const measurementName = component.type.name || getMeasurementByName(component.type.uuid);
                        productData.expDate = {
                            value: component.amount,
                            measurement: {
                                label: measurementName,
                                value: component.type.uuid
                            }
                        };
                    } else if (isNutrient(component)) {  // Если компонент — питательное вещество
                        const ingredientName = getIngredientByName(component.componentId);
                        const mappedKey = nutrientMapping[ingredientName.toLowerCase()];
                        if (mappedKey) {
                            productData[mappedKey] = component.amount;
                        }
                    }
                });


                return (
                    <>
                        <div className="form-item">
                            <label htmlFor="productName">Название</label>
                            <input id="productName" required name="name" type="text" defaultValue={clickedProduct.name} placeholder='' />
                        </div>

                        <div className="form-item">
                            <label htmlFor="productSubtitle">Подпись</label>
                            <input id="productSubtitle" required name="subtitle" type="text" defaultValue={clickedProduct.subtitle} placeholder='' />
                        </div>

                        <div className="form-item">
                            <label id="labelProductManufacturer" htmlFor="productManufacturer">Производитель</label>
                            <CreatableSelect
                                id="productManufacturer"
                                isLoading={loading}
                                placeholder="Выберите производителя"
                                options={manufacturersOptions}
                                aria-labelledby="labelProductManufacturer"
                                onChange={handleManufacturerChange}
                                defaultValue={clickedProductManufacturer}
                                formatCreateLabel={(inputValue) => `Добавить производителя "${inputValue}"`}
                                noOptionsMessage={() => 'Введите название производителя'}
                                isRequired
                            />
                        </div>

                        <div className="form-item product-descr">
                            <label htmlFor="form-descr">Описание</label>
                            <textarea rows="1" onInput={resizeTextarea} required name="description" className="form-control" defaultValue={clickedProduct.description} id="form-descr"></textarea>
                        </div>

                        <div className="uploadImg">
                            <div className="form-item">
                                <label htmlFor="productImage">Ссылка на изображение</label>
                                <input id="productImage" required name="imageURL" type="text" defaultValue={clickedProduct.imageURL} placeholder='' />
                                <span className="error-message d-none">Ссылка недействительна.</span>
                            </div>
                            <picture>
                                <img src={clickedProduct.imageURL} alt="Капуста" />
                            </picture>
                            <div className="form-item">
                                <button type="button" className="btn" onClick={handleImageUpdate}>Обновить</button>
                            </div>
                        </div>

                        <div className="form-item">
                            <label id="labelProductMeasurement" htmlFor="productMeasurement">Состав</label>
                            <CreatableSelect
                                id="productMeasurement"
                                isLoading={loading}
                                placeholder="Введите состав"
                                options={componentsOptions}
                                aria-labelledby="labelProductMeasurement"
                                onChange={handleIngridientsChange}
                                defaultValue={composition}
                                formatCreateLabel={(inputValue) => `Добавить "${inputValue}"`}
                                noOptionsMessage={() => 'Введите ингридиент'}
                                isRequired
                                isMulti
                            />
                        </div>

                        <div className="form-item">
                            <label htmlFor="productArticle">Артикул</label>
                            <input id="productArticle" required name="article" type="text" defaultValue={clickedProduct.article} placeholder='' />
                        </div>


                        <div className="article-and-size">
                            <div className="form-item">
                                <label htmlFor="productSize">Размер/Объем/Кол-во</label>
                                <input id="productSize" required name="size" type="number" defaultValue={clickedProduct.size} placeholder='' />
                            </div>

                            <div className="form-item">
                                <label id="labelProductMeasurement" htmlFor="productMeasurement">ед.</label>
                                <CreatableSelect
                                    id="productMeasurement"
                                    isLoading={loading}
                                    placeholder="Выберите"
                                    options={measurementsOptions}
                                    aria-labelledby="labelProductMeasurement"
                                    onChange={handleMeasurementChange}
                                    defaultValue={clickedProductMeasurement}
                                    formatCreateLabel={(inputValue) => `Добавить единицу измерения "${inputValue}"`}
                                    noOptionsMessage={() => 'Введите единицу измерения'}
                                    isRequired
                                />
                            </div>
                        </div>

                        <div className="article-and-size">
                            <div className="form-item">
                                <label htmlFor="productExp">Срок хранения</label>
                                <input id="productExp" required name="exp" type="number" defaultValue={!!productData.expDate ? productData.expDate.value : ''} placeholder='' />
                            </div>

                            <div className="form-item">
                                <label id="labelProductExpMeasurement" htmlFor="productExpMeasurement">ед.</label>
                                <CreatableSelect
                                    id="productExpMeasurement"
                                    isLoading={loading}
                                    placeholder="Выберите"
                                    options={measurementsOptions}
                                    aria-labelledby="labelProductExpMeasurement"
                                    onChange={handleExpMeasurementChange}
                                    defaultValue={productData.expDate.measurement}
                                    formatCreateLabel={(inputValue) => `Добавить единицу измерения "${inputValue}"`}
                                    noOptionsMessage={() => 'Введите единицу измерения'}
                                    isRequired
                                />
                            </div>
                        </div>

                        <div className="kcal">

                            <div className="form-item">
                                <label htmlFor="kcal">ККАЛ*</label>
                                <input id="kcal" name="kcal" type="number" defaultValue={!!productData.kcal ? productData.kcal : ''} placeholder='' />
                            </div>

                            <div className="form-item">
                                <label htmlFor="protein">БЕЛКИ*</label>
                                <input id="protein" name="protein" type="number" defaultValue={!!productData.protein ? productData.protein : ''} placeholder='' />
                            </div>

                            <div className="form-item">
                                <label htmlFor="fat">ЖИРЫ*</label>
                                <input id="fat" name="fat" type="number" defaultValue={!!productData.fat ? productData.fat : ''} placeholder='' />
                            </div>

                            <div className="form-item">
                                <label htmlFor="carbos">УГЛЕВОДЫ*</label>
                                <input id="carbos" name="carbos" type="number" defaultValue={!!productData.carbs ? productData.carbs : ''} placeholder='' />
                            </div>

                        </div>

                        <p>* Укажите информацию о продукте на 100 г</p>

                        <div className="form-item submit">
                            <button type="submit" onSubmit={handleFormSubmit}>Сохранить исправленный продукт</button>
                        </div>
                    </>
                )
            }
        } else {
            return <></>
        }
    }

    const selectedProducts = tab === "main" ? waitForReviewProducts : commentedProducts;
    const content = selectedProducts.length && !error ? (<View products={selectedProducts} manufacturers={manufacturers} measurements={measurements} tab={tab} openProductDetails={openProductDetails} />) : null;

    const errorMessage = error ? <ErrorMessage /> : null;
    const skeleton = loading ? <Skeleton pageName={"MyReviewProductList"} /> : null;

    return (
        <>
            <div className="container d-flex justify-content-between align-items-center">

                <ul className="breadcrumbs">
                    {waitForReviewProducts.length > 0 && (tab === 'main' ?
                        <li>
                            <h1 style={{ "padding": "20px 0", "margin": "-20px 0", "lineHeight": "normal" }}>На рассмотрении</h1>
                        </li>
                        :
                        <li>
                            <button type="button" className='tab' style={{ "color": "#8F9092" }} onClick={openMainTab}><h1>На рассмотрении</h1></button>
                        </li>
                    )}
                    {commentedProducts.length > 0 && (
                        tab === 'main' ?
                            <li>
                                <button type="button" className='tab' onClick={openCommentedTab} style={{ "color": "#8F9092" }}><h1>/ Проблемы ({commentedProducts.length})</h1></button>
                            </li>
                            : <li>
                                <h1 style={{ "padding": "20px 0", "margin": "-20px 0", "lineHeight": "normal" }}>{waitForReviewProducts.length > 0 && '/'}Проблемы ({commentedProducts.length})</h1>
                            </li>
                    )}
                </ul>

                <div className="wrapper d-flex align-items-center" style={{ "gap": "20px" }}>
                    <p>После проверки вы сможете найти продукт и добавить его в свой магазин.</p>
                </div>

            </div>

            {errorMessage}

            {
                !error ?
                    <>
                        <div className="catalog">
                            <div className="container">

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ "width": "67px" }} scope="col" className='text-left'>Фото</th>
                                            <th scope="col" className='text-left'>Название</th>
                                            <th scope="col">Производитель</th>
                                            <th style={{ "width": "200px" }} scope="col">Размер/Объем/Кол-во</th>
                                            <th scope="col">Артикул</th>
                                            <th scope="col">Статус</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {skeleton}
                                        {content}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </>
                    : null
            }

            <Modal modalIsOpen={modalIsOpen} toggleModal={toggleModal} renderForm={renderForm} handleFormSubmit={handleFormSubmit} />
        </>
    )

}

const View = ({ products, manufacturers, measurements, tab, openProductDetails }) => {

    const findManufacturerName = (uuid) => {
        const foundManufacturer = manufacturers.find(manufacturer => manufacturer.id === uuid);
        return foundManufacturer ? foundManufacturer.name : 'Загрузка...';
    }

    const statusName = (status) => {

        switch (status) {
            case "waitForReview":
                return <span className='text-success'>Ожидает проверки</span>;
            case "commented":
                return <span className='text-danger'>Оставлен комментарий.</span>;
            default:
                return <span className='text-light'>Неизвестен</span>;
        }
    }

    const findMeasurementName = (uuid) => {
        const foundMeasurement = measurements.find(measurement => measurement.id === uuid);
        return foundMeasurement ? foundMeasurement.name : '...';
    }

    const items = products.map((item, i) => {
        return (
            <tr key={item.id}>
                <th scope="row" className='text-left' style={{ "paddingLeft": 0, "paddingRight": 0 }}>
                    <button onClick={() => {
                        openProductDetails(item, measurements)
                    }}>
                        <picture>
                            <img src={item.imageURL} alt={item.name} />
                        </picture>
                    </button>
                </th>
                <td>
                    {item.name}
                    {item.subtitle && <><br /><small>{item.subtitle}</small></>}
                </td>
                {/* Производитель */}
                <td className='text-center'>
                    {typeof (item.manufacturer) === "object" ? (item.manufacturer.name ? item.manufacturer.name : findManufacturerName(item.manufacturer.uuid)) : item.manufacturer}
                </td>
                <td className='text-center'>{item.size} {typeof (item.measurement) === "object" ? (item.measurement.name ? item.measurement.name : findMeasurementName(item.measurement.uuid)) : item.measurement}</td>
                <td className='text-center'>{item.article}</td>

                <td className='text-center'>
                    {statusName(item.status)}
                    {tab !== "main" && (
                        <button
                            onClick={() => {
                                openProductDetails(item, measurements)
                            }}
                            type='button'
                            className='top__add'
                            style={{
                                "fontSize": "15px",
                                "width": "initial",
                                "height": "initial",
                                "margin": "auto",
                                "padding": "5px 10px"
                            }}>
                            Подробнее
                        </button>
                    )}

                </td>


            </tr >
        )
    });

    return (
        <>
            {items}
        </>
    )
}

export default ReviewsPartnerPage;