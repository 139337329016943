import './skeleton.scss';

const Skeleton = ({ pageName }) => {

    const ProfileList = () => {
        const Item = () => {
            return (
                <li className="account__item skeleton">

                    <div className="account__title">
                        <div className="pulse" style={{ "width": "250px", "height": "25px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>

                        <div className="bottom-controls">
                            <div className="pulse" style={{ "width": "220px", "height": "19px", "backgroundColor": "#C4C4C4", "marginTop": "10px" }}></div>
                        </div>
                    </div>

                    <div className="account__info">
                        <ul className="account__descr" style={{ 'minHeight': '238px' }}>
                            <li>
                                <div className="pulse" style={{ "width": "200px", "height": "19px", "backgroundColor": "#C4C4C4" }}></div>
                            </li>
                            <li>
                                <div className="pulse" style={{ "width": "200px", "height": "19px", "backgroundColor": "#C4C4C4", "marginTop": "10px" }}></div>
                            </li>

                            <li>
                                <div className="pulse" style={{ "width": "100px", "height": "19px", "backgroundColor": "#C4C4C4", "marginTop": "25px" }}></div>
                            </li>

                            <li>
                                <div className="pulse" style={{ "width": "200px", "height": "19px", "backgroundColor": "#C4C4C4", "marginTop": "10px" }}></div>
                            </li>

                        </ul>
                        <div className="account__bottom">
                            <div className="pulse" style={{ "width": "245px", "height": "19px", "backgroundColor": "#C4C4C4", "marginTop": "10px" }}></div>
                        </div>
                    </div>
                </li>
            )
        }

        return (
            <>
                <ul className="account__body">
                    <Item />
                    <Item />
                    <Item />
                </ul>
            </>
        )
    }

    const ShopList = () => {
        const Item = () => {
            return (
                <li>
                    <div className={'shops-list__item empty'} item-count={0}>

                        <div className="pulse" style={{ "width": "250px", "height": "25px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>

                        <div className="shops-list__bottom">

                            <div className="pulse" style={{ "width": "155px", "height": "19px", "backgroundColor": "#C4C4C4", "marginTop": "10px" }}></div>

                            <div className="pulse" style={{ "width": "220px", "height": "19px", "backgroundColor": "#C4C4C4", "marginTop": "10px" }}></div>

                        </div>
                    </div>
                </li>
            )
        }

        return (
            <ul className="shops-list__body">
                <Item />
                <Item />
                <Item />
                <Item />
                <Item />
                <Item />
            </ul>
        )
    }

    const SimpleList = () => {
        const Item = () => {
            return (
                <li className="user-list__item">
                    <ul>
                        <li style={{ "display": "block" }}>
                            <div className="pulse" style={{ "width": "75%", "height": "25px", "backgroundColor": "#C4C4C4" }}></div>
                        </li>

                        <li>
                            <div className="pulse" style={{ "width": "220px", "height": "19px", "backgroundColor": "#C4C4C4", "marginTop": "10px" }}></div>
                        </li>

                        <li>
                            <div className="pulse" style={{ "width": "200px", "height": "19px", "backgroundColor": "#C4C4C4", "marginTop": "10px" }}></div>
                        </li>

                    </ul>
                </li >
            )
        }
        const items = [];

        for (let i = 0; i < 12; i++) {
            items.push(<Item key={i} />);
        }

        return (
            <>
                {items}
            </>
        );

    }

    const AdminList = () => {
        const Item = () => {
            return (
                <>
                    <li className="contacts__item">
                        <ul>
                            <li>
                                <div className="pulse" style={{ "width": "75%", "height": "25px", "backgroundColor": "#C4C4C4" }}></div>
                            </li>
                            <li>
                                <div className="pulse" style={{ "width": "220px", "height": "19px", "backgroundColor": "#C4C4C4", "marginTop": "4px" }}></div>
                            </li>
                        </ul>

                        <div className="bottom-controls justify-content-end">
                            <div className="pulse" style={{ "width": "99px", "height": "19px", "backgroundColor": "#C4C4C4", "marginTop": "10px" }}></div>
                        </div>
                    </li >
                </>
            )
        }
        const items = [];

        for (let i = 0; i < 5; i++) {
            items.push(<Item key={i} />);
        }

        return (
            <>
                {items}
            </>
        );

    }

    const ProductList = () => {
        const Item = () => {
            return (
                <>
                    <tr>
                        <th scope="row" className='text-left' style={{ "paddingLeft": 0, "paddingRight": 0 }}>
                            <button>
                                <picture>
                                    <div className="pulse" style={{ "display": "block", "width": "50px", "height": "50px", "backgroundColor": "#C4C4C4", "borderRadius": "100%" }}></div>
                                </picture>
                            </button>
                        </th>
                        <td>
                            <div className="pulse" style={{ "width": "116px", "height": "18px", "backgroundColor": "#C4C4C4" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "150px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "100px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "98px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "20px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                    </tr>

                </>
            )
        }
        const items = [];

        for (let i = 0; i < 12; i++) {
            items.push(<Item key={i} />);
        }

        return (
            <>
                {items}
            </>
        );

    }

    const MyReviewProductList = () => {
        const Item = () => {
            return (
                <>
                    <tr>
                        <th scope="row" className='text-left' style={{ "paddingLeft": 0, "paddingRight": 0 }}>
                            <button>
                                <picture>
                                    <div className="pulse" style={{ "display": "block", "width": "50px", "height": "50px", "backgroundColor": "#C4C4C4", "borderRadius": "100%" }}></div>
                                </picture>
                            </button>
                        </th>
                        <td>
                            <div className="pulse" style={{ "width": "116px", "height": "18px", "backgroundColor": "#C4C4C4" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "150px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "100px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "98px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                    </tr>

                </>
            )
        }
        const items = [];

        for (let i = 0; i < 12; i++) {
            items.push(<Item key={i} />);
        }

        return (
            <>
                {items}
            </>
        );

    }

    const OrderList = () => {
        const Item = () => {
            return (
                <>
                    <tr>
                        <th scope="row" style={{ "paddingLeft": 0, "paddingRight": 0 }}>
                            <button>
                                <picture>
                                    <div className="pulse" style={{ "display": "block", "width": "50px", "height": "50px", "backgroundColor": "#C4C4C4", "borderRadius": "100%" }}></div>
                                </picture>
                            </button>
                        </th>
                        <td>
                            <div className="pulse" style={{ "width": "80px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "45px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "15px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "45px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "15px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "45px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                        <td className='text-center'>
                            <div className="pulse" style={{ "width": "60px", "height": "18px", "backgroundColor": "#C4C4C4", "margin": "auto" }}></div>
                        </td>
                    </tr>

                </>
            )
        }
        const items = [];

        for (let i = 0; i < 12; i++) {
            items.push(<Item key={i} />);
        }

        return (
            <>
                {items}
            </>
        );

    }

    return (
        <>
            {pageName === "ProfileList" ? <ProfileList /> : null}
            {pageName === "UserList" ? <SimpleList /> : null}
            {pageName === "AdminList" ? <AdminList /> : null}
            {pageName === "ProductList" ? <ProductList /> : null}
            {pageName === "MyReviewProductList" ? <MyReviewProductList /> : null}
            {pageName === "ShopList" ? <ShopList /> : null}
            {pageName === "OrderList" ? <OrderList /> : null}
        </>
    )
}

export default Skeleton;