import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";

import useBazarkenService from '../../../services/BazarkenService';
import "react-datepicker/dist/react-datepicker.css";

import "./styles/moneyInfoWidget.scss"

function MoneyInfoWidget({ storeId }) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [moneyInfo, setMoneyInfo] = useState(null);

    const { loading, error, getMoneyInfo, clearError } = useBazarkenService();

    useEffect(() => {
        setMoneyInfo(null)
    }, [storeId])

    const toISO8601WithoutMillis = (date) => {
        return date.toISOString().split('.')[0] + "Z";
    };

    const handleDateChange = (date) => {
        // Устанавливаем дату на первое число выбранного месяца
        const newDate = new Date(date.getFullYear(), date.getMonth(), 1);
        setSelectedDate(newDate);
        console.log(date)
    };

    const handleSubmit = () => {
        clearError()
        const dateFrom = toISO8601WithoutMillis(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1));
        const dateTo = toISO8601WithoutMillis(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0));

        const requestData = {
            dateFrom: dateFrom,
            dateTo: dateTo
        };

        getMoneyInfo(storeId, requestData)
            .then(setMoneyInfo)

    };

    return (
        <>
            <div className='moneyInfoModal'>
                <div>
                    <span>Выберите месяц: </span>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                    />

                </div>
                <button onClick={handleSubmit} disabled={loading}>
                    Получить статистику
                </button>

                {moneyInfo ?
                    <div className="moneyCircle">
                        <span>всего:</span>
                        <span>{moneyInfo.finalPriceSum}&#x20bd;</span>
                        <span>доставка:</span>
                        <span>{moneyInfo.deliveryPriceSum}&#x20bd;</span>
                    </div>
                    : null
                }
            </div>
        </>
    );
}

export default MoneyInfoWidget;
