import { useState } from 'react';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';

import useBazarkenService from '../../services/BazarkenService';

import './styles/loginPage.scss';
import logo from '../../resources/img/icons/logo.svg'

const RegPage = ({ onLogin }) => {
    const [formState, setFormState] = useState({
        phone: '',
        firstName: '',
        lastName: ''
    });
    const [messageBoxVisible, setMessageBoxVisible] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { error, clearError, isPhoneFree, registerUser, getPhone } = useBazarkenService();

    const handleInputChange = (e) => {
        if (e.target.name === "phone") {
            const formattedValue = e.target.value.replace(/[\s-()]/g, '')

            setFormState({
                ...formState,
                [e.target.name]: formattedValue
            });
        } else {
            setFormState({
                ...formState,
                [e.target.name]: e.target.value
            });
        }

        if (phoneNumber) {
            setPhoneNumber(null);
            setMessageBoxVisible(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { phone, firstName, lastName } = formState;

        if (phoneNumber) {
            // Регистрация пользователя
            const userData = {
                loginData: {
                    phone: {
                        phone: phone
                    },
                    authType: 'receiveCall'
                },
                lastName: lastName,
                firstName: firstName
            };

            registerUser(userData)
                .then(onLogin)
                .catch((error) => {
                    // Обработка ошибки регистрации
                    // console.error('Ошибка регистрации:', error.response);
                    document.querySelector('.message-box').style.color = '#FF5E5E';
                })
        } else {
            // Проверка доступности телефона
            isPhoneFree(phone)
                .then((isFree) => {
                    if (isFree) {
                        // Получение номера телефона
                        getPhone(phone)
                            .then((response) => {
                                const approvePhone = response.phone;

                                // Обновление состояния
                                setPhoneNumber(approvePhone);
                                setMessageBoxVisible(true);
                                setErrorMessage('');
                            })
                            .catch((error) => {
                                // Обработка ошибки получения номера телефона
                                // console.error('Ошибка получения номера телефона:', error);
                                setErrorMessage('Ошибка получения номера телефона. Попробуйте позже.');
                            });
                    } else {
                        setErrorMessage('Телефон уже занят. Пожалуйста, используйте другой номер.');
                    }
                })
                .catch((error) => {
                    console.error('Ошибка проверки телефона:', error);
                    setErrorMessage('Ошибка проверки телефона. Попробуйте позже.');
                });
        }

    };

    return (
        <div className="login-page">
            <div className="login-page__body">

                <div className="login-page__top">
                    <img src={logo} alt="" />
                    <h2>Регистрация</h2>
                </div>

                <form className="login-page__form" onSubmit={handleSubmit}>

                    <div className="form-item login">
                        <label htmlFor="login">Телефон</label>

                        <InputMask
                            id="login"
                            mask="+7 (999) 999-9999"
                            placeholder="+7"
                            type="text"
                            name="phone"
                            value={formState.phone}
                            onChange={handleInputChange}
                        />

                        <svg style={{ "marginLeft": "-2px" }} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1915_10989)">
                                <path d="M4.96094 13.8379C7.7832 16.6602 11.2109 18.8379 14.0137 18.8379C15.2734 18.8379 16.377 18.3984 17.2656 17.4219C17.7832 16.8457 18.1055 16.1719 18.1055 15.5078C18.1055 15.0195 17.9199 14.5508 17.4609 14.2188L14.4629 12.0898C14.0039 11.7773 13.623 11.6211 13.2715 11.6211C12.832 11.6211 12.4316 11.875 11.9824 12.3145L11.2891 12.998C11.1816 13.1055 11.0449 13.1543 10.918 13.1543C10.7715 13.1543 10.625 13.0957 10.5273 13.0469C9.92188 12.7246 8.88672 11.8359 7.91992 10.8789C6.96289 9.92188 6.07422 8.88672 5.76172 8.28125C5.71289 8.17383 5.6543 8.03711 5.6543 7.89062C5.6543 7.76367 5.69336 7.63672 5.80078 7.5293L6.49414 6.81641C6.92383 6.36719 7.1875 5.97656 7.1875 5.52734C7.1875 5.17578 7.02148 4.79492 6.69922 4.33594L4.59961 1.37695C4.25781 0.908203 3.7793 0.703125 3.25195 0.703125C2.60742 0.703125 1.94336 0.996094 1.36719 1.55273C0.419922 2.46094 0 3.58398 0 4.82422C0 7.62695 2.13867 11.0254 4.96094 13.8379Z" fill="black"></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_1915_10989">
                                    <rect width="18.1055" height="18.8379" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div className="form-item firstname">
                        <label htmlFor="firstname">Имя</label>

                        <input required id="firstname" type="text" name="firstName" value={formState.firstName} onChange={handleInputChange} />

                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1672_8570)">
                                <path d="M1.66992 17.8809H14.7656C15.8105 17.8809 16.4355 17.3926 16.4355 16.582C16.4355 14.0625 13.2812 10.5859 8.21289 10.5859C3.1543 10.5859 0 14.0625 0 16.582C0 17.3926 0.625 17.8809 1.66992 17.8809ZM8.22266 8.83789C10.3125 8.83789 12.1289 6.96289 12.1289 4.52148C12.1289 2.10938 10.3125 0.322266 8.22266 0.322266C6.13281 0.322266 4.31641 2.14844 4.31641 4.54102C4.31641 6.96289 6.12305 8.83789 8.22266 8.83789Z" fill="black" fillOpacity="0.85" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1672_8570">
                                    <rect width="16.4355" height="17.8809" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div className="form-item lastname">
                        <label htmlFor="lastname">Фамилия</label>

                        <input required id="lastname" type="text" name="lastName" value={formState.lastName} onChange={handleInputChange} />

                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1672_8570)">
                                <path d="M1.66992 17.8809H14.7656C15.8105 17.8809 16.4355 17.3926 16.4355 16.582C16.4355 14.0625 13.2812 10.5859 8.21289 10.5859C3.1543 10.5859 0 14.0625 0 16.582C0 17.3926 0.625 17.8809 1.66992 17.8809ZM8.22266 8.83789C10.3125 8.83789 12.1289 6.96289 12.1289 4.52148C12.1289 2.10938 10.3125 0.322266 8.22266 0.322266C6.13281 0.322266 4.31641 2.14844 4.31641 4.54102C4.31641 6.96289 6.12305 8.83789 8.22266 8.83789Z" fill="black" fillOpacity="0.85" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1672_8570">
                                    <rect width="16.4355" height="17.8809" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div className={`error-message`}>
                        <small>{errorMessage}</small>
                    </div>

                    <div className="message-box" style={messageBoxVisible ? { display: 'block' } : { display: 'none' }}>
                        <p>Для подтверждения регистрации, с указанного вами телефона<br></br>позвоните по номеру <Link to={`tel:+${phoneNumber}`}>+{phoneNumber}</Link></p>
                        <p></p>
                        <p>После звонка нажмите "Зарегистрироваться"</p>
                    </div>

                    <div className="form-item">
                        <div className="form-item submit">
                            <input type="submit" value="Зарегистрироваться" />
                        </div>
                    </div>
                    <Link to={`/login`}>Уже зарегистрированы?</Link>
                </form>
            </div >
        </div >
    )
}

export default RegPage;