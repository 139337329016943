import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

const Products = ({ products, addReviewProduct, reviewProducts, deleteReviewProduct, deleteProduct, addProductToStore, tab, editStock, editPrice, measurements, manufacturers, openProductDetails, findManufacturerName, findMeasurementName, statusName }) => {
    const [cookies] = useCookies(['user']);
    const [role] = useState(cookies?.user?.user?.roles[0]);
    const location = useLocation();

    const items = products.map((item) => {

        return (
            <tr key={item.id}>
                <th scope="row" className='text-left' style={{ "paddingLeft": 0, "paddingRight": 0, "verticalAlign": "middle" }}>

                    <button type="button" onClick={() => openProductDetails(item)}>
                        <picture>
                            <img src={item.imageURL} alt={item.name} />
                        </picture>
                    </button>

                </th>

                {/* Название и подпись */}
                <td>
                    {role === 'superuser' ?
                        <button type="button" onClick={() => openProductDetails(item)}>
                            {item.name}
                        </button>
                        :
                        item.name}

                    {item.subtitle && <><br /><small>{item.subtitle}</small>
                    </>}
                </td>



                {/* Производитель */}
                <td className='text-center'>
                    {typeof (item.manufacturer) === "object" ? (item.manufacturer.name ? item.manufacturer.name : findManufacturerName(item.manufacturer.uuid)) : item.manufacturer}
                </td>

                {/* Размер/Объем/Кол-во */}
                <td className='text-center'>
                    {item.size} {typeof (item.measurement) === "object" ? (item.measurement.name ? item.measurement.name : findMeasurementName(item.measurement.uuid)) : item.measurement}
                </td>

                {/* Артикул */}
                <td className='text-center'>{item.article}</td>

                {(role === 'partner' || role === 'admin' || (role === 'superuser' && location.pathname.includes('/shops/'))) && tab === 'main' ?
                    <>
                        <td style={{ "width": "65px", 'whiteSpace': 'nowrap', 'textAlign': 'center', 'fontWeight': 'bold' }}>
                            <div className="d-flex align-items-center justify-content-center">

                                {item.secondPrice ?
                                    <div className='d-flex flex-column' style={{ 'padding': '0 5px 0 0' }}>
                                        <span style={{ 'textDecoration': 'line-through', 'padding': '0 0 0.5rem 0' }}>{item.secondPrice} р.
                                        </span>
                                        <span style={{ "padding": 0 }}>
                                            {item.price} р.
                                        </span>
                                    </div>
                                    :
                                    <>
                                        {item.price} р.
                                    </>
                                }

                                {role === 'partner' || role === 'admin' ?
                                    <svg onClick={() => editPrice(item)} xmlns="http://www.w3.org/2000/svg" style={{ 'padding': 0, "marginBottom": '-5px', 'cursor': 'pointer' }} version="1.1" xlink="http://www.w3.org/1999/xlink" width="20" height="20" x="0" y="0" viewBox="0 0 24 24">
                                        <g>
                                            <g data-name="Layer 92">
                                                <path d="M21.1 2.9a3 3 0 0 0-4.27 0l-1.92 1.95 4.24 4.24 1.92-1.92a3 3 0 0 0 .03-4.27zM13.49 6.27 5.1 14.66a1.15 1.15 0 0 0-.19.26l-2.78 5.61A1 1 0 0 0 3 22a1 1 0 0 0 .44-.1l5.61-2.78a1.15 1.15 0 0 0 .26-.19l8.39-8.39zM21 22h-9a1 1 0 0 1 0-2h9a1 1 0 0 1 0 2z" fill="#9dbdb0" data-original="#000000" opacity="1"></path>
                                            </g>
                                        </g>
                                    </svg>
                                    : null
                                }

                            </div>
                        </td>

                        <td style={{ "width": "65px", 'whiteSpace': 'nowrap', 'textAlign': 'center', 'fontWeight': 'bold' }}>
                            {<span>
                                {item.stock}
                                {role === 'partner' || role === 'admin' ?
                                    <svg onClick={() => editStock(item)} xmlns="http://www.w3.org/2000/svg" style={{ 'padding': 0, "marginBottom": '-5px', 'cursor': 'pointer' }} version="1.1" xlink="http://www.w3.org/1999/xlink" width="20" height="20" x="0" y="0" viewBox="0 0 24 24">
                                        <g>
                                            <g data-name="Layer 92">
                                                <path d="M21.1 2.9a3 3 0 0 0-4.27 0l-1.92 1.95 4.24 4.24 1.92-1.92a3 3 0 0 0 .03-4.27zM13.49 6.27 5.1 14.66a1.15 1.15 0 0 0-.19.26l-2.78 5.61A1 1 0 0 0 3 22a1 1 0 0 0 .44-.1l5.61-2.78a1.15 1.15 0 0 0 .26-.19l8.39-8.39zM21 22h-9a1 1 0 0 1 0-2h9a1 1 0 0 1 0 2z" fill="#9dbdb0" data-original="#000000" opacity="1"></path>
                                            </g>
                                        </g>
                                    </svg>
                                    : null
                                }
                            </span>}

                        </td>

                    </>
                    : null}

                {products === reviewProducts ?
                    <td className='text-center'>
                        {statusName(item.status)}
                        {item.status === 'waitForReview' ?
                            <button
                                onClick={() => openProductDetails(item)}
                                type='button'
                                className='top__add'
                                style={{
                                    "fontSize": "15px",
                                    "width": "initial",
                                    "height": "initial",
                                    "margin": "auto",
                                    "padding": "5px 10px"
                                }}>
                                Подробнее
                            </button>
                            : null
                        }

                    </td> : null}

                {role === 'partner' && tab === 'search' ?
                    <td className='text-center' style={{ "fontSize": "25px" }}>
                        <button
                            onClick={() => addProductToStore(item)}
                            type='button'
                            className='top__add'
                            style={{
                                "width": "40px",
                                "height": "40px",
                                "margin": "auto"
                            }}>
                            +
                        </button>
                    </td> : null}

                <td>
                    <button
                        onClick={() => {
                            openProductDetails(item)
                        }}
                        type='button'
                        className='top__add'
                        style={{
                            "fontSize": "15px",
                            "width": "initial",
                            "height": "initial",
                            "padding": "5px 10px"
                        }}>
                        Подробнее
                    </button>
                </td>

                {role === 'superuser' ?
                    <td className='text-center'>
                        <button
                            type="button"
                            onClick={(e) => {
                                e.currentTarget.parentNode.parentNode.classList.add('loading');
                                products === reviewProducts ? deleteReviewProduct(item.id) : deleteProduct(item.id);
                            }}
                        >
                            <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.16602 4.46289H6.71875V2.37305C6.71875 1.81641 7.10938 1.45508 7.69531 1.45508H11.1914C11.7773 1.45508 12.168 1.81641 12.168 2.37305V4.46289H13.7207V2.27539C13.7207 0.859375 12.8027 0 11.2988 0H7.58789C6.08398 0 5.16602 0.859375 5.16602 2.27539V4.46289ZM0.732422 5.24414H18.1836C18.584 5.24414 18.9062 4.90234 18.9062 4.50195C18.9062 4.10156 18.584 3.76953 18.1836 3.76953H0.732422C0.341797 3.76953 0 4.10156 0 4.50195C0 4.91211 0.341797 5.24414 0.732422 5.24414ZM4.98047 21.748H13.9355C15.332 21.748 16.2695 20.8398 16.3379 19.4434L17.0215 5.05859H15.4492L14.7949 19.2773C14.7754 19.8633 14.3555 20.2734 13.7793 20.2734H5.11719C4.56055 20.2734 4.14062 19.8535 4.11133 19.2773L3.41797 5.05859H1.88477L2.57812 19.4531C2.64648 20.8496 3.56445 21.748 4.98047 21.748ZM6.5625 18.6035C6.93359 18.6035 7.17773 18.3691 7.16797 18.0273L6.86523 7.57812C6.85547 7.23633 6.61133 7.01172 6.25977 7.01172C5.88867 7.01172 5.64453 7.24609 5.6543 7.58789L5.94727 18.0273C5.95703 18.3789 6.20117 18.6035 6.5625 18.6035ZM9.45312 18.6035C9.82422 18.6035 10.0879 18.3691 10.0879 18.0273V7.58789C10.0879 7.24609 9.82422 7.01172 9.45312 7.01172C9.08203 7.01172 8.82812 7.24609 8.82812 7.58789V18.0273C8.82812 18.3691 9.08203 18.6035 9.45312 18.6035ZM12.3535 18.6035C12.7051 18.6035 12.9492 18.3789 12.959 18.0273L13.252 7.58789C13.2617 7.24609 13.0176 7.01172 12.6465 7.01172C12.2949 7.01172 12.0508 7.23633 12.041 7.58789L11.748 18.0273C11.7383 18.3691 11.9824 18.6035 12.3535 18.6035Z" fill="#8F9092"></path>
                            </svg>
                        </button>
                    </td> : null}



            </tr >
        )
    });

    return (
        <>
            {items}
        </>
    )
}

export default Products;
