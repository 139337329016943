import { useState, useEffect } from 'react';

import useBazarkenService from '../../services/BazarkenService';
import Modal from './Modal';
import { Skeleton, ErrorMessage } from '../pages/index';

import './styles/profileContactListPage.scss';
import './styles/userListPage.scss';

const UserListPage = () => {
	// Следим за состоянием модального окна
	const [modalIsOpen, setModalIsOpen] = useState(false);
	// Переключаем модальное окно
	const toggleModal = () => {
		setModalIsOpen(!modalIsOpen)
	};

	const [store, setStore] = useState(null);
	const [firstLoad, setFirstLoad] = useState(true);
	const [clickedUser, setClickedUser] = useState([]);

	const { loading, error, clearError, getAdminAllUsers, getUser } = useBazarkenService();

	useEffect(() => {
		updateStore()
	}, [])

	const onStoreLoaded = (store) => {
		setStore(store);
	}

	const updateStore = () => {
		clearError()

		getAdminAllUsers()
			.then(onStoreLoaded)
			.then(() => { setFirstLoad(false) })
	}

	const renderForm = () => {
		return (
			<>
				<p>ID: {clickedUser.userId}</p>
				<p>Имя: {clickedUser.firstname ? clickedUser.firstname : 'Не указано'}</p>
				<p>Фамилия: {clickedUser.surname ? clickedUser.surname : 'Не указано'}</p>
				<p>Телефон: {clickedUser.phone ? clickedUser.phone : 'Не указано'}</p>

				<div className="form-item submit">
					<button type="submit">Сохранить</button>
				</div>
			</>
		)
	}

	const showInfo = (id) => {
		clearError()
		getUser(id)
			.then(res => setClickedUser(res))
			.then(toggleModal)
	}

	const content = !(!store) && !error ? (<View partners={store} showInfo={showInfo} />) : null;

	const errorMessage = error ? <ErrorMessage /> : null;
	const skeleton = loading && firstLoad ? <Skeleton pageName={"UserList"} /> : null;

	return (
		<>
			<div className="container d-flex justify-content-between align-items-center">
				<ul className="breadcrumbs">
					<li>
						<h1 style={{ "padding": "20px 0", "margin": "-20px 0", "lineHeight": "normal" }}>Пользователи</h1>
					</li>
				</ul>
			</div>
			<div className="contacts">
				<div className="container">

					{errorMessage}

					<ul className="user-list">
						{skeleton}
						{content}
					</ul>

				</div>
			</div >
			<Modal modalIsOpen={modalIsOpen} toggleModal={toggleModal} renderForm={renderForm} handleFormSubmit={toggleModal} />
		</>
	)

}

const View = ({ partners, showInfo }) => {

	const items = partners.items.map((item, i) => {
		return (
			<li key={item.userId} className="contacts__item">
				<ul>
					<li>
						<span>ID:</span>
						<span>{item.userId}</span>
					</li>

					{(item.firstname || item.surname) && (
						<li>
							<span>Имя:</span>
							<span>{item.firstname ? `${item.firstname} ` : null}{item.surname ?? null}</span>
						</li>
					)}

					{item.phone && (
						<li>
							<span>Телефон:</span>
							<span>{item.phone}</span>
						</li>
					)}

				</ul>
				<div className="bottom-controls justify-content-between">

					<button className="del" title="Отвязать партнера" type="button" onClick={() => showInfo(item.userId)}>
						<span>ПОДРОБНЕЕ</span>
						<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" x="0" y="0" viewBox="0 0 488.85 488.85" space="preserve">
							<g>
								<path d="M244.425 98.725c-93.4 0-178.1 51.1-240.6 134.1-5.1 6.8-5.1 16.3 0 23.1 62.5 83.1 147.2 134.2 240.6 134.2s178.1-51.1 240.6-134.1c5.1-6.8 5.1-16.3 0-23.1-62.5-83.1-147.2-134.2-240.6-134.2zm6.7 248.3c-62 3.9-113.2-47.2-109.3-109.3 3.2-51.2 44.7-92.7 95.9-95.9 62-3.9 113.2 47.2 109.3 109.3-3.3 51.1-44.8 92.6-95.9 95.9zm-3.1-47.4c-33.4 2.1-61-25.4-58.8-58.8 1.7-27.6 24.1-49.9 51.7-51.7 33.4-2.1 61 25.4 58.8 58.8-1.8 27.7-24.2 50-51.7 51.7z" fill="#9dbdb0"></path>
							</g>
						</svg>
					</button>
				</div>
			</li >
		)
	});

	return (
		<>
			{items}
		</>
	)
}

export default UserListPage;