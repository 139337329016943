import { useParams, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import useBazarkenService from '../../services/BazarkenService';
import Modal from './Modal';
import { Skeleton, ErrorMessage } from '../pages/index';

const ProductComponentsPage = () => {

    // Следим за состоянием модального окна
    const [modalIsOpen, setModalIsOpen] = useState(false);
    // Переключаем модальное окно
    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen)
    };

    const [items, setItems] = useState(null);
    const { loading, error, clearError, getComponents, addComponent, removeComponent } = useBazarkenService();

    useEffect(() => {
        updateItems()
    }, [])

    const onStoreLoaded = (items) => {
        setItems(items);
    }

    const updateItems = () => {
        clearError()

        getComponents()
            .then(onStoreLoaded)
    }

    const deleteMeasurement = (id) => {
        if (window.confirm("Вы действительно хотите удалить производителя?")) {
            // clearError()
            removeComponent(id)
                .then(updateItems)
        }
    }

    const newMeasurement = async (id) => {
        clearError()

        return await addComponent(id)
            .then(updateItems)
            .then(toggleModal)
    }

    const handleFormSubmit = (event) => {
        // Предотвращаем обновление страницы
        event.preventDefault();
        // Получаем значение из поля ввода
        const name = event.target.elements.name.value;
        // Вызываем функцию newMeasurement с полученным значением
        newMeasurement(name)
            .then(() => {
                // Получаем все элементы input
                let inputs = document.getElementsByTagName('input');

                // Проходимся по каждому элементу input
                for (let i = 0; i < inputs.length; i++) {
                    // Если тип поля text, email, password, etc., то очищаем его
                    if (inputs[i].type === 'text' || inputs[i].type === 'password' || inputs[i].type === 'email') {
                        inputs[i].value = ''; // Устанавливаем значение в пустую строку
                    }
                }

            });
    }

    function renderForm() {
        return (
            <>
                <div className="form-item">
                    <label>Название</label>
                    <input name="name" type="text" defaultValue="" placeholder='' />
                </div>

                <div className="form-item submit">
                    <button type="submit">Сохранить</button>
                </div>
            </>
        )
    }

    const content = !(!items) && !error ? (<View result={items} toggleModal={toggleModal} deleteMeasurement={deleteMeasurement} />) : null;

    const errorMessage = error ? <ErrorMessage /> : null;
    const skeleton = loading ? <Skeleton pageName={"AdminList"} /> : null;

    return (
        <>
            <div className="container d-flex justify-content-between align-items-center">
                <ul className="breadcrumbs">
                    <li>
                        <h1 style={{ "padding": "20px 0", "margin": "-20px 0", "lineHeight": "normal" }}>Компоненты продуктов</h1>
                    </li>
                </ul>
                {!error
                    ?
                    <div className="wrapper d-flex align-items-center" style={{ "gap": "20px" }}>
                        <button className="top__add" onClick={toggleModal}>+ Добавить</button>
                    </div>
                    :
                    null
                }
            </div>
            <div className="contacts">
                <div className="container">
                    {errorMessage}

                    <ul className="contacts__body">
                        {skeleton}
                        {content}
                    </ul>

                </div>
            </div>
            <Modal modalIsOpen={modalIsOpen} toggleModal={toggleModal} handleFormSubmit={handleFormSubmit} renderForm={renderForm} />
        </>
    )

}

const View = ({ result, toggleModal, deleteMeasurement }) => {

    const items = result.items.map((item, i) => {
        return (
            <li key={item.id} className="contacts__item">
                <ul>
                    <li>
                        <span>ID:</span>
                        <span>{item.id}</span>
                    </li>
                    <li>
                        <span>Название:</span>
                        <span>{item.name}</span>
                    </li>
                </ul>

                <div className="bottom-controls justify-content-end">

                    <button className="del" title="Отвязать партнера" onClick={() => deleteMeasurement(item.id)}>
                        <span>УДАЛИТЬ</span>
                        <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_2070_8907)">
                                <path d="M5.16602 4.46289H6.71875V2.37305C6.71875 1.81641 7.10938 1.45508 7.69531 1.45508H11.1914C11.7773 1.45508 12.168 1.81641 12.168 2.37305V4.46289H13.7207V2.27539C13.7207 0.859375 12.8027 0 11.2988 0H7.58789C6.08398 0 5.16602 0.859375 5.16602 2.27539V4.46289ZM0.732422 5.24414H18.1836C18.584 5.24414 18.9062 4.90234 18.9062 4.50195C18.9062 4.10156 18.584 3.76953 18.1836 3.76953H0.732422C0.341797 3.76953 0 4.10156 0 4.50195C0 4.91211 0.341797 5.24414 0.732422 5.24414ZM4.98047 21.748H13.9355C15.332 21.748 16.2695 20.8398 16.3379 19.4434L17.0215 5.05859H15.4492L14.7949 19.2773C14.7754 19.8633 14.3555 20.2734 13.7793 20.2734H5.11719C4.56055 20.2734 4.14062 19.8535 4.11133 19.2773L3.41797 5.05859H1.88477L2.57812 19.4531C2.64648 20.8496 3.56445 21.748 4.98047 21.748ZM6.5625 18.6035C6.93359 18.6035 7.17773 18.3691 7.16797 18.0273L6.86523 7.57812C6.85547 7.23633 6.61133 7.01172 6.25977 7.01172C5.88867 7.01172 5.64453 7.24609 5.6543 7.58789L5.94727 18.0273C5.95703 18.3789 6.20117 18.6035 6.5625 18.6035ZM9.45312 18.6035C9.82422 18.6035 10.0879 18.3691 10.0879 18.0273V7.58789C10.0879 7.24609 9.82422 7.01172 9.45312 7.01172C9.08203 7.01172 8.82812 7.24609 8.82812 7.58789V18.0273C8.82812 18.3691 9.08203 18.6035 9.45312 18.6035ZM12.3535 18.6035C12.7051 18.6035 12.9492 18.3789 12.959 18.0273L13.252 7.58789C13.2617 7.24609 13.0176 7.01172 12.6465 7.01172C12.2949 7.01172 12.0508 7.23633 12.041 7.58789L11.748 18.0273C11.7383 18.3691 11.9824 18.6035 12.3535 18.6035Z" fill="#8F9092" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2070_8907">
                                    <rect width="18.9062" height="23.4863" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                </div>
            </li >
        )
    });

    return (
        <>
            {items}

            <li className="contacts__add" onClick={toggleModal}>
                <button>
                    <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.5 75.9635C58.0148 75.9635 75 58.9497 75 38.4817C75 17.977 57.9779 1 37.4632 1C16.9853 1 0 17.977 0 38.4817C0 58.9497 17.022 75.9635 37.5 75.9635ZM19.5588 38.5184C19.5588 36.6076 20.8823 35.2847 22.7941 35.2847H34.3014V23.783C34.3014 21.8722 35.5882 20.5125 37.4632 20.5125C39.375 20.5125 40.6983 21.8722 40.6983 23.783V35.2847H52.2428C54.1176 35.2847 55.4778 36.6076 55.4778 38.5184C55.4778 40.3927 54.1176 41.642 52.2428 41.642H40.6983V53.1805C40.6983 55.0912 39.375 56.4143 37.4632 56.4143C35.5882 56.4143 34.3014 55.0544 34.3014 53.1805V41.642H22.7941C20.8823 41.642 19.5588 40.3927 19.5588 38.5184Z" fill="#D9D9D9" />
                    </svg>
                </button>
            </li>
        </>
    )
}

export default ProductComponentsPage;