import { useHttp } from "../hooks/http.hook";
import { useCookies } from 'react-cookie';


import { v4 as uuidv4 } from 'uuid';
// import md5 from 'md5';
const useBazarkenService = () => {

    const { loading, request, error, clearError } = useHttp();

    const [cookies] = useCookies(['user']);

    const getHeaders = () => {

        if (cookies.user && cookies.user.token) {

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookies.user.token}`
                // 'Authorization': `Bearer foo`
                // 'Authorization': `Bearer yLq5psFcFbnlF+qe2xxZNQ==`
            }
            return headers;
        }

        const headers = {
            'Content-Type': 'application/json',
        }
        return headers;
    }

    // console.log(cookies.user);

    // Функция для проверки доступности телефона для регистрации.
    // Принимает номер телефона в виде строки и возвращает Promise
    // c результатом запроса.
    const isPhoneFree = async (phone) => {
        const body = {
            "phone": phone
        }

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}user/signup/isPhoneFree`, 'POST', body);
        return res;
    }

    // Функция для получения телефона пользователя.
    // Принимает номер телефона в виде строки и возвращает Promise
    // с результатом запроса.
    const getPhone = async (phone) => {
        const body = {
            "phone": phone
        }

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}user/signup/get/phone`, 'POST', body);

        return res;
    }

    // Функция для регистрации пользователя.
    // Принимает номер телефона, фамилию и имя пользователя.
    // Возвращает Promise с результатом запроса.
    const registerUser = async (userData) => {
        const body = userData

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}user/signup/sms`, 'POST', body);
        return res;
    }

    const login = async (login, password) => {
        const body = {
            "phone": {
                "phone": login
            },
            "password": password
        }

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}user/login/phone`, 'POST', body
        );
        // return _transformCharacter(res.data.results[0]);
        return res;
    }

    const getMe = async () => {

        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}user/me`, 'GET', null, headers);
        return res;
    }

    const setPassword = async (password) => {
        const headers = getHeaders();

        const body = {
            "password": password
        }

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}user/password`, 'PUT', body, headers);

            if (res.ok) {
                return true;
            }
        } catch (e) {
        }

        // console.log(res);
        // return _transformCharacter(res.data.results[0]);
        return false;
    }

    const delPassword = async (password) => {
        const headers = getHeaders();


        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}user/password`, 'DELETE', null, headers);
        // console.log(res);
        // return _transformCharacter(res.data.results[0]);
        return res;
    }

    const getAllStores = async () => {
        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}stores/all`);

        return res;
    }

    // СуперАдмин, Админ, Партнер
    // Получить список магазинов
    const getAdminAllStores = async () => {
        const headers = getHeaders();

        if (cookies.user.user.roles.includes("superuser")) {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/stores/all?per=10000`, 'GET', null, headers);

            return res;
        }

        if (cookies.user.user.roles.includes("admin")) {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/stores`, 'GET', null, headers);
            return res;
        }

        if (cookies.user.user.roles.includes("partner")) {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}partner/stores`, 'GET', null, headers);
            return res;
        }

        return false;
    }

    // СуперАдмин, Админ
    // Получить список партнеров у магазина
    // {userId, storeId, permissionLevel}
    const getAdminStorePartners = async (storeId) => {
        const headers = getHeaders();
        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/store/${storeId}/partners`, 'GET', null, headers);

        return res;
    }

    // СуперАдмин, Админ
    // Получить список партнеров у магазина
    // {userId, storeId, permissionLevel}
    const getAdminStoreAdmins = async (storeId) => {
        const headers = getHeaders();
        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/store/${storeId}/admins`, 'GET', null, headers);

        return res;
    }

    // Админ
    // Получить список всех юзеров
    // {}
    const getAdminAllUsers = async () => {
        const headers = getHeaders();
        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/users?per=1000`, 'GET', null, headers);

        return res;
    }

    // СуперАдмин
    // Выдать права партнера
    // {}
    const addPartnerPermission = async (userId, storeId) => {
        const headers = getHeaders();

        const body = {
            "userId": userId,
            "permissions": [
                {
                    "permissionLevel": "all",
                    "storeId": storeId
                }
            ]
        };

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/add/partner/permissions`, 'POST', body, headers);
            if (res.ok) {
                return true;
            }
        } catch (e) {
        }

        return false;
    }

    // СуперАдмин
    // Выдать права партнера
    // {}
    const addAdminPermission = async (userId, storeId) => {
        const headers = getHeaders();

        const body = {
            "userId": userId,
            "permissions": [
                {
                    "permissionLevel": "all",
                    "storeId": storeId
                }
            ]
        };

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/add/admin/permissions`, 'POST', body, headers);
            if (res.ok) {
                return true;
            }
        } catch (e) {
        }

        return false;
    }

    // СуперАдмин
    // Забрать права партнера
    // {}
    const removePartnerPermission = async (userId, storeId) => {
        const headers = getHeaders();
        // 4E623971-1F73-4B18-80D7-016CEB1C97A2

        const body = {
            "userId": userId,
            "permissions": [
                {
                    "permissionLevel": "all",
                    "storeId": storeId
                }
            ]
        };

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/remove/partner/permissions`, 'DELETE', body, headers);
            if (res.ok) {
                return true;
            }
        } catch (e) {
            // console.log(e);
        }

        return false;
    }

    // СуперАдмин
    // Забрать права админа
    // {}
    const removeAdminPermission = async (userId, storeId) => {
        const headers = getHeaders();
        // 4E623971-1F73-4B18-80D7-016CEB1C97A2

        const body = {
            "userId": userId,
            "permissions": [
                {
                    "permissionLevel": "all",
                    "storeId": storeId
                }
            ]
        };

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/remove/admin/permissions`, 'DELETE', body, headers);
            if (res.ok) {
                return true;
            }
        } catch (e) {
            // console.log(e);
        }

        return false;
    }

    // Админ
    // Создать магазин
    // {}
    const addStore = async (inn, name, ogrn, about, deliveryPrice) => {
        const headers = getHeaders();

        const body = {
            "inn": inn,
            // "isWorksNow": true,
            "isBanned": false,
            "name": name,
            "ogrn": ogrn,
            "aboutPartner": about ? about : '',
            "deliveryPrice": deliveryPrice ? parseInt(deliveryPrice) : 0
        };

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/partnerStore`, 'PUT', body, headers);
        return res;
    }

    // Админ | Партнер
    // Получить продукты магазина
    // {}
    const getProducts = async (storeId) => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}partner/${storeId}/availableProducts?per=10000`, 'GET', null, headers);

        return res;
    }

    // СуперАдмин | Админ | Партнер
    // Получить продукты магазина
    // {}
    const getProduct = async (id) => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}product/${id}`, 'GET', null, headers);

        return res;
    }

    // Админ | Партнер
    // Добавить товар в наличие
    // {}
    const setProductInStock = async (storeId, productId, stock) => {
        const headers = getHeaders();

        const body = {
            "product": productId,
            "stock": parseInt(stock)
        };

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}partner/store/${storeId}/stock`, 'POST', body, headers);

            if (res.ok) {
                return true;
            }
        } catch (e) {
        }

        return false;
    }

    // Админ | Партнер
    // Удалить товар из наличия
    // {}
    const removeProductInStock = async (storeId, productId) => {
        const headers = getHeaders();

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}partner/store/${storeId}/product/${productId}/stock`, 'DELETE', null, headers);

            if (res.ok) {
                return true;
            }
        } catch (e) {
        }

        return false;
    }

    // Админ | Партнер
    // Установить цену на продукт для магазина
    // {}
    const setProductPrice = async (storeId, productId, price, secondPrice) => {
        const headers = getHeaders();

        let date = new Date(); // Получаем текущую дату и время
        date.setSeconds(date.getSeconds() + (60 * 60 * 3) + 5); // Добавляем 5 секунд к текущему времени
        let futureDateTime = date.toISOString(); // Преобразуем новую дату и время в строку ISO 8601

        let formattedFutureDateTime = futureDateTime.slice(0, -5) + futureDateTime.slice(-1)

        const body = {
            "price": parseInt(price),
            "secondPrice": parseInt(secondPrice),
            "startDate": formattedFutureDateTime,
            "product": productId
        };

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}partner/store/${storeId}/price`, 'PUT', body, headers);

            if (res.ok) {
                return true;
            }
        } catch (e) {
        }

        return false;
    }

    // Админ | Партнер
    // Получить ВСЕ продукты
    // {}
    const searchProducts = async () => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}suggestions/search/partner/nil?per=10000`, 'GET', null, headers);

        return res;
    }

    // Админ, Партнер
    // Список измерений продуктов
    // {}
    const getMeasurements = async () => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}internal/measurement/all?per=1000`, 'GET', null, headers);

        return res;
    }

    // СуперАдмин
    // Добавить измерение продуктов
    // {}
    const addMeasurement = async (name) => {
        const headers = getHeaders();

        const body = {
            "name": name,
            "id": uuidv4()
        };

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}internal/measurement`, 'PUT', body, headers);

        return res;
    }

    // СуперАдмин
    // Удалить измерение продуктов
    // {}
    const removeMeasurement = async (id) => {
        const headers = getHeaders();

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}internal/measurement/${id}`, 'DELETE', null, headers);
            if (res.ok) {
                return true;
            }
        } catch (e) {
        }

        return false;
    }

    // Админ, Партнер
    // Список производителей
    // {}
    const getManufacturers = async () => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}internal/manufacturer/all?per=1000`, 'GET', null, headers);

        return res;
    }

    // СуперАдмин
    // Добавить производителя
    // {}
    const addManufacturer = async (name) => {
        const headers = getHeaders();

        const body = {
            "name": name,
            "id": uuidv4()
        };

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}internal/manufacturer`, 'PUT', body, headers);

        return res;
    }

    // СуперАдмин
    // Удалить производителя
    // {}
    const removeManufacturer = async (id) => {
        const headers = getHeaders();

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}internal/manufacturer/${id}`, 'DELETE', null, headers);
            if (res.ok) {
                return true;
            }
        } catch (e) {
        }

        return false;
    }

    // Админ, Партнер
    // Список компонентов продуктов
    // {}
    const getComponents = async () => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}internal/components/all?per=1000`, 'GET', null, headers);

        return res;
    }

    // СуперАдмин
    // Добавить компонент
    // {}
    const addComponent = async (name) => {
        const headers = getHeaders();

        const body = {
            "name": name,
            "id": uuidv4()
        };

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}internal/components`, 'PUT', body, headers);

        return res;
    }

    // СуперАдмин
    // Удалить компонент
    // {}
    const removeComponent = async (id) => {
        const headers = getHeaders();

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}internal/components/${id}`, 'DELETE', null, headers);
            if (res.ok) {
                return true;
            }
        } catch (e) {
        }

        return false;
    }

    // СуперАдмин
    // Добавить продукт
    // {}
    const addProduct = async (measurementId, manufacturerId, name, description, imageURL, subtitle, article, size) => {
        const headers = getHeaders();

        const body = {
            "measurement": {
                "id": measurementId
            },
            "size": parseFloat(size),
            "manufacturer": {
                "id": manufacturerId
            },
            "name": name,
            "description": description,
            "imageURL": imageURL,
            "subtitle": subtitle,
            "article": article
        };

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}product`, 'PUT', body, headers);

        return res;
    }

    // СуперАдмин
    // Удалить продукт
    //
    const delProduct = async (id) => {
        const headers = getHeaders();

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}product/${id}`, 'DELETE', null, headers);
            if (res.ok) {
                return true;
            }
        } catch (e) {
        }

        return false;
    }

    // СуперАдмин
    // Получить продукты находищиеся на review
    // {}
    const getReviewProducts = async () => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}productReview/list`, 'GET', null, headers);

        return res;
    }

    // Партнер
    // Добавить продукт на ревью
    // {}
    const addProductToReview = async (measurement, manufacturer, name, description, imageURL, subtitle, article, size, components) => {
        const headers = getHeaders();

        const body = {
            "components": components,
            "manufacturer": manufacturer,
            "measurement": measurement,
            "name": name,
            "imageURL": imageURL,
            "size": parseFloat(size),
            "subtitle": subtitle,
            "article": article,
            "description": description
        };

        console.log(JSON.stringify(body));

        // return true;

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}productReview/addProductToReview`, 'POST', body, headers);

        return res;
    }

    // Партнер
    // Отредактировать ревью продукт
    // {}
    const editProductToReview = async (measurement, manufacturer, name, description, imageURL, subtitle, article, size, components, id) => {
        const headers = getHeaders();

        const body = {
            "components": components,
            "manufacturer": manufacturer,
            "measurement": measurement,
            "name": name,
            "imageURL": imageURL,
            "size": parseFloat(size),
            "subtitle": subtitle,
            "article": article,
            "description": description
        };

        console.log(JSON.stringify(body));

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}productReview/${id}`, 'PATCH', body, headers);

        return res;
    }

    // СуперАдмин
    // Принять продукт на ревью
    // 
    const acceptReviewProduct = async (id) => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}productReview/${id}/accept`, 'POST', null, headers);

        return res;
    }


    // СуперАдмин
    // Удалить продукт
    //
    const delReviewProduct = async (id) => {
        const headers = getHeaders();

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}productReview/${id}`, 'DELETE', null, headers);
            if (res.ok) {
                return true;
            }
        } catch (e) {
        }

        return false;
    }

    // Партнер
    // Список продуктов находящих на review
    // {}
    const getMyReviewProducts = async () => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}productReview/my/list`, 'GET', null, headers);

        return res;
    }

    // Партнер
    // Список заказов магазина
    // {}
    const getShopOrders = async (id) => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}orders/store/${id}?per=10000&sort=descending&page=1`, 'GET', null, headers);

        return res;
    }

    // Партнер
    // Список всех заказов
    // {}
    const getMyOrders = async () => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}orders/all?per=10000&page=1`, 'GET', null, headers);

        return res;
    }

    // ВСЕ
    // Список всех категорий с товарами
    // {}
    const getAllCategories = async () => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}category/all?per=10000&page=1`, 'GET', null, headers);

        return res;
    }

    // ВСЕ
    // Список товаров в категории
    // {}
    const getCategory = async (id, partnerStore) => {
        const headers = getHeaders();

        let queryString = `${process.env.REACT_APP_URL_ACTIVE}category/${id}?showWithoutStock=false&showWithoutPrice=false`;

        if (partnerStore) {
            queryString += `&partnerStore=${partnerStore}`;
        }
        const res = await request(queryString, 'GET', null, headers);

        return res;
    }

    // СуперАдмин
    // Добавить категорию продуктов
    // {}
    const addCategory = async (name) => {
        const headers = getHeaders();

        const body = {
            "name": name,
            "id": uuidv4(),
            "products": [],
            "imageURL": null
        };

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}category`, 'PUT', body, headers);

        return res;
    }

    // СуперАдмин, Админ, Партнер, Клиент
    // Получить адресс заказа
    // {}
    const getAddress = async (id) => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}user/edit/address/${id}`, 'GET', null, headers);


        // if (!res.ok) {
        // return { 'Адрес': "не найден" };
        // }

        return res;
    }

    // Админ, СуперАдмин
    // Информация о юзере
    // {}
    const getUser = async (id) => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/user/${id}`, 'GET', null, headers);

        return res;
    }

    // СуперАдмин
    // Задать зону работы магазина
    // {}
    const setWorkzone = async (id, coordinates) => {
        const headers = getHeaders();

        const body = JSON.parse(coordinates);

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/partnerStore/${id}/workZone`, 'PUT', body, headers);

            if (res.ok) {
                return true;
            }
        } catch (e) {
        }
    }

    // Партнер
    // Перевести заказ на следующий статус
    // {}
    const nextStatus = async (storeId, orderId) => {
        const headers = getHeaders();

        const body = null;

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}order/${orderId}/store/${storeId}/nextStatus`, 'POST', body, headers);

        return res;
    }

    // СуперАдмин
    // Бан магазина
    // 
    const banStore = async (id) => {
        const headers = getHeaders();

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/partnerStore/${id}/suspend`, 'POST', null, headers);
            if (res.ok) {
                return true;
            }

        } catch (e) {
        }

        return false;
    }

    // СуперАдмин
    // Разбан магазина
    // 
    const unbanStore = async (id) => {
        const headers = getHeaders();

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/partnerStore/${id}/unsuspend`, 'POST', null, headers);
            if (res.ok) {
                return true;
            }

        } catch (e) {
        }

        return false;
    }

    // СуперАдмин Админ
    // Комментарий для ревью
    // 
    const commentReviewProduct = async (id, comment) => {
        const headers = getHeaders();

        const body = {
            "comment": comment
        };

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}productReview/${id}/comment`, 'POST', body, headers);
            if (res.ok) {
                return true;
            }

        } catch (e) {
        }

        return false;
    }

    // СуперАдмин Админ Партнер
    // Комментарий для ревью
    // 
    const setWorkingStatus = async (id, status) => {
        const headers = getHeaders();

        try {
            const res = await request(`${process.env.REACT_APP_URL_ACTIVE}partner/store/${id}/setStatus/${status}`, 'POST', null, headers);
            if (res.ok) {
                return true;
            }

        } catch (e) {
        }

        return false;
    }

    // СуперАдмин Админ
    // Добавление продукта в категорию
    // 200
    const setCategory = async (id, productId) => {
        const headers = getHeaders();
        console.log(id + ' | ' + productId)

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}category/${id}/addProduct/${productId}`, 'PUT', null, headers);

        return res;
    }

    // СуперАдмин Админ
    // Добавление продукта в категорию
    // 200
    const unsetCategory = async (id, productId) => {
        const headers = getHeaders();

        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}category/${id}/remove/${productId}`, 'DELETE', null, headers);

        return res;
    }

    // СуперАдмин
    // Получение информации о сумме заказов по конкретному магазину за запрошенный период.
    // 200
    const getMoneyInfo = async (id, body) => {
        const headers = getHeaders();


        const res = await request(`${process.env.REACT_APP_URL_ACTIVE}admin/store/${id}/moneyInfo`, 'POST', body, headers);
        console.log(res)

        return res;
    }

    return {
        loading, error, clearError, login, getAllStores, getAdminAllStores, getAdminStorePartners, getAdminAllUsers, removePartnerPermission, addPartnerPermission, getProducts, addProduct, getMeasurements, addMeasurement, removeMeasurement, getManufacturers, addManufacturer, removeManufacturer, addStore, searchProducts, isPhoneFree, getPhone, registerUser, getMe, setPassword, delPassword, getComponents, addComponent, removeComponent, delProduct, getReviewProducts, delReviewProduct, acceptReviewProduct, setProductInStock, setProductPrice, addProductToReview, getMyReviewProducts, getShopOrders, getMyOrders, getAllCategories, addCategory, removeProductInStock, getAddress, getUser, setWorkzone, nextStatus, getCategory, banStore, unbanStore, commentReviewProduct, setWorkingStatus, editProductToReview, setCategory, unsetCategory, getProduct, getMoneyInfo, getAdminStoreAdmins, addAdminPermission, removeAdminPermission
    }
}

export default useBazarkenService;