import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Modal = ({ modalIsOpen, toggleModal, renderForm, handleFormSubmit, onFormRequest }) => {
    const location = useLocation();

    // Добавляем слушателя событий после рендеринга компонента
    useEffect(() => {
        // Закрыть
        const popups = document.querySelectorAll(".popup");
        if (popups) {
            popups.forEach(function (item) {
                item.addEventListener("click", function closeModal(e) {
                    if (e.target.matches(".popup") || e.target.matches(".popup__close")) {
                        toggleModal();
                        e.target.removeEventListener("click", closeModal);
                    };
                });
            });
        };
    }, [modalIsOpen]);

    const form = renderForm();

    return (
        <div className={modalIsOpen ? 'popup active' : 'popup'} id="contact-change">
            <div className="popup__window" style={{ "padding": location.pathname.includes('/orders/') ? '38px 0 109px 0' : '38px 90px 109px 80px' }}>
                <form className="popup__form-contacts" onSubmit={handleFormSubmit}>
                    {form}
                </form>
            </div>
        </div>
    )
}

export default Modal;
