import { useRef, useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import useBazarkenService from '../../services/BazarkenService';

import { Skeleton, ErrorMessage } from '../pages/index';
import Modal from './Modal';
import Search from './widgets/Search';
import Products from './widgets/Products';

import './styles/productListPage.scss';
import './styles/productModal.scss';
import './styles/table.scss';
import './widgets/styles/filter.scss';
import imgIcon from '../../resources/img/icons/img.svg';

const ProductListPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [cookies] = useCookies(['user']);
    const [role] = useState(cookies?.user?.user?.roles[1] ? cookies?.user?.user?.roles[1] : cookies?.user?.user?.roles[0]);

    const textareaRef = useRef(null);
    const prevSelectedCategoriesRef = useRef();

    // Следим за состоянием модального окна
    const [modalIsOpen, setModalIsOpen] = useState(false);
    // Переключаем модальное окно
    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen)
    };

    const { storeId } = useParams();

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [myProducts, setMyProducts] = useState([]);
    const [myFilteredProducts, setMyFilteredProducts] = useState([]);
    const [reviewProducts, setReviewProducts] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [tab, setTab] = useState("main");

    // Стэйты для формы
    const [manufacturers, setManufacturers] = useState([]);
    const [measurements, setMeasurements] = useState([]);
    const [gramm, setGramm] = useState([]);

    const [selectedListManufacturer, setSelectedListManufacturer] = useState(null);
    const [selectedManufacturer, setSelectedManufacturer] = useState(null);
    const [selectedMeasurement, setSelectedMeasurement] = useState(null);
    const [selectedExpMeasurement, setSelectedExpMeasurement] = useState(null);
    const [selectedIngridients, setSelectedIngridients] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [components, setComponents] = useState([]);
    const [ingridients, setIngridients] = useState([]);

    const [categories, setCategories] = useState([]);
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [activeCategoryId, setActiveCategoryId] = useState(null);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [clickedProduct, setClickedProduct] = useState(null);
    const [currentAction, setCurrentAction] = useState(null);

    const { loading, error, searchProducts, clearError, addProduct, getProducts, delProduct, getReviewProducts, delReviewProduct, acceptReviewProduct, getManufacturers, getMeasurements, setProductInStock, setProductPrice, addProductToReview, getComponents, getAllCategories, getCategory, commentReviewProduct, setCategory, unsetCategory, getProduct } = useBazarkenService();

    useEffect(() => {
        clearError()

        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }

        onRequest()
            .then(() => { setFirstLoad(false) })
    }, []) // Если подставить пустой массив то функция вызовется только один раз

    // Перед функцией handleCategoryChange
    useEffect(() => {
        prevSelectedCategoriesRef.current = selectedCategories;
    }, [selectedCategories]);

    useEffect(() => {
        // Находим продукт, который был выбран

        const selectedProductName = selectedProduct ? products.find(product => product.id === selectedProduct)?.name : null;

        const selectedManufacturerName = selectedListManufacturer ? manufacturers.find(manufacturer => manufacturer.id === selectedListManufacturer)?.name : null;

        // Фильтруем продукты, исключая выбранный продукт
        let filteredProducts = products;

        if (selectedProductName) {
            filteredProducts = filteredProducts.filter(product => product.name === selectedProductName);
        }

        if (selectedManufacturerName) {
            filteredProducts = filteredProducts.filter(product => product.manufacturer === selectedManufacturerName);
        }

        // Теперь отфильтруем товары, которые уже находятся в myProducts
        if (myProducts && myProducts.length > 0) {
            const myProductIds = myProducts.map(product => product.id); // Получаем массив ID товаров в myProducts
            filteredProducts = filteredProducts.filter(product => !myProductIds.includes(product.id)); // Отфильтровываем товары, ID которых содержатся в myProductIds

            let myFilteredProducts = myProducts;
            if (selectedProductName) {
                myFilteredProducts = myFilteredProducts.filter(product => product.name === selectedProductName);
            }

            if (selectedManufacturerName) {
                myFilteredProducts = myFilteredProducts.filter(product => product.manufacturer === selectedManufacturerName);
            }

            setMyFilteredProducts(myFilteredProducts);
        }

        // Сортировка товаров по имени
        myFilteredProducts.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        setFilteredProducts(filteredProducts)

    }, [selectedProduct, selectedListManufacturer, products, myProducts])

    const onRequest = () => {

        getManufacturers()
            .then(res => setManufacturers(res.items))

        getAllCategories()
            .then(res => {
                const sortedCategories = res.items.sort((a, b) => a.name.localeCompare(b.name));

                setCategories(sortedCategories)
                setCategoriesOptions(() => {
                    function buildTree(categories, parent = null) {
                        const newCats = categories
                            .filter(cat => (cat.parentCategory || null) === parent)
                            .map(cat => ({
                                value: cat.id,
                                label: cat.name,
                                children: buildTree(categories, cat.id)
                            }));


                        return newCats;
                    }
                    function flattenTree(tree, depth = 0) {
                        return tree.reduce((acc, item) => {
                            const prefix = '--- '.repeat(depth);
                            const label = `${prefix}${item.label}`;
                            return acc.concat(
                                { value: item.value, label },
                                ...flattenTree(item.children, depth + 1)
                            );
                        }, []);
                    }

                    const tree = buildTree(sortedCategories);
                    const flattenedOptions = flattenTree(tree);

                    return flattenedOptions;
                })

                // console.log(JSON.stringify(updatedItems))
            }
            )

        if (location.pathname.includes('/shops/')) {
            if (role === 'partner' || role === 'admin') {
                const productsPromise = searchProducts().then(onProductsLoaded);
                const myProductsPromise = getProducts(storeId).then(onMyProductsLoaded);
                return Promise.all([productsPromise, myProductsPromise]);

            } else {
                return getProducts(storeId)
                    .then(onProductsLoaded)
            }
        }

        const productsPromise = searchProducts().then(onProductsLoaded);

        if (role === 'superuser') {
            const reviewProductsPromise = getReviewProducts().then(onReviewProductsLoaded);

            return Promise.all([productsPromise, reviewProductsPromise]);
        }

        if (role === 'partner' || role === 'admin') {
            const myProductsPromise = getProducts(storeId).then(onMyProductsLoaded);
            return Promise.all([productsPromise, myProductsPromise]);
        }
    }

    const onFormRequest = () => {
        return getMeasurements()
            // .then(res => setManufacturers(res.items))
            // .then(getMeasurements)
            .then(res => {

                const gramm = res.items.find(item => (item.name.toLowerCase() === 'гр' || item.name.toLowerCase() === 'гр.' || item.name.toLowerCase() === 'грамм'));

                setMeasurements(res.items)
                setGramm(gramm)

            })
            .then(getComponents)
            .then(res => {

                setIngridients(res.items);
                const newComponents = res.items.reduce((acc, item) => {
                    acc[item.name.toLowerCase()] = item.id;
                    return acc;
                }, {});

                setComponents(newComponents);
            })

    }

    const onMyProductsLoaded = (newProducts) => {
        setMyProducts(newProducts.items)
    }

    const onProductsLoaded = (newProducts) => {
        setFilteredProducts(products)
        setProducts(newProducts.items)
    }

    const onReviewProductsLoaded = (newProducts) => {
        setReviewProducts(newProducts)
    }

    const newProduct = async (measurementId, manufacturerId, name, description, imageURL, subtitle, article, size) => {
        clearError()

        return await addProduct(measurementId, manufacturerId, name, description, imageURL, subtitle, article, size)
            .then(onRequest)
            .then(toggleModal)
    }

    const addProductToStore = (product) => {
        setCurrentAction('addProductToStore');
        setClickedProduct(product)
        toggleModal()
    }

    const editStock = (product) => {
        setCurrentAction('editStock');
        setClickedProduct(product)
        toggleModal()
    }

    const editPrice = (product) => {
        setCurrentAction('editPrice');
        setClickedProduct(product)
        toggleModal()
    }

    const openModalNewProductReview = async () => {
        setCurrentAction('createNewProduct');
        setClickedProduct(null)
        onFormRequest()
        toggleModal()
    }

    const openProductDetails = (product) => {
        setCurrentAction('productDetails');

        switch (tab) {
            case "review":
                setClickedProduct(product);
                break;
            case "main":
                getProduct(product.id)
                    .then(res => {
                        setClickedProduct(res)
                        setSelectedCategories(categoriesOptions.filter(option => {
                            const cleanedLabel = option.label.replace(/^--- /, ''); // Удаляем префикс "--- "
                            return res.categories.some(category => category.name === cleanedLabel);
                        }))

                        console.log(res)
                        console.log(categoriesOptions)
                    })
                break;
            default:
                setClickedProduct(product);
        }
        toggleModal()
    }

    const handleFormSubmit = (event) => {
        event.preventDefault()
        clearError()

        if (role === 'partner' || role === 'admin') {

            if (tab === 'search') {
                if (currentAction === 'createNewProduct') {

                    // Стандартные свойства объекта
                    // Данные о товаре
                    const name = event.target.elements.name.value;
                    const imageURL = event.target.elements.imageURL.value;
                    const size = event.target.elements.size.value;
                    const subtitle = event.target.elements.subtitle.value;
                    const article = event.target.elements.article.value;
                    const description = event.target.elements.description.value;

                    // Компоненты
                    // КБЖУ на 100 г.
                    const kcal = event.target.elements.kcal.value;
                    const protein = event.target.elements.protein.value;
                    const fat = event.target.elements.fat.value;
                    const carbos = event.target.elements.carbos.value;

                    // Срок хранения
                    const exp = event.target.elements.exp.value;

                    const type = gramm ? {
                        "uuid": gramm.id
                    } : {
                        "name": "гр"
                    };

                    const productComponents = [
                        {
                            type,
                            "perVolume": 100,
                            "amount": parseInt(kcal),
                            ...(components['ккал'] ? {
                                "componentId": components['ккал'],
                            } : {
                                "componentName": 'ККАЛ',
                            }),
                        },

                        {
                            type,
                            "perVolume": 100,
                            "amount": parseInt(protein),
                            ...(components['белки'] ? {
                                "componentId": components['белки'],
                            } : {
                                "componentName": 'Белки',
                            }),
                        },
                        {
                            type,
                            "perVolume": 100,
                            "amount": parseInt(fat),
                            ...(components['жиры'] ? {
                                "componentId": components['жиры'],
                            } : {
                                "componentName": 'Жиры',
                            }),
                        },
                        {
                            type,
                            "perVolume": 100,
                            "amount": parseInt(carbos),
                            ...(components['углеводы'] ? {
                                "componentId": components['углеводы'],
                            } : {
                                "componentName": 'Углеводы',
                            }),
                        },
                        {
                            "amount": parseInt(exp),
                            ...(components['срок хранения'] ? {
                                "componentId": components['срок хранения'],
                            } : {
                                "componentName": 'Срок хранения',
                            }),
                            ...selectedExpMeasurement.uuid ? {
                                "type": { "uuid": selectedExpMeasurement.uuid },
                            } : {
                                "type": { "name": selectedExpMeasurement.name },
                            },
                        },
                    ];

                    const productIngridients = selectedIngridients.map((item) => {
                        return {
                            ...item.value ? {
                                "componentId": item.value,
                            } : {
                                "componentName": item.label,
                            },
                        }
                    });

                    const allComponents = [...productComponents, ...productIngridients];

                    addProductToReview(selectedMeasurement, selectedManufacturer, name, description, imageURL, subtitle, article, size, allComponents)
                        .then(toggleModal)
                        .then(() => navigate('/review', { state: { fromAddReviewProduct: true } }))


                } else {
                    const stock = event.target.elements.stock.value;
                    const price = event.target.elements.price.value;
                    const secondPrice = event.target.elements.secondPrice.value;

                    setProductInStock(storeId, clickedProduct.id, stock)
                        .then(() => setProductPrice(storeId, clickedProduct.id, price, secondPrice))
                        .then(toggleModal)
                        .then(() => setTab('main'))
                        .then(onRequest)
                }
            }

            if (tab === 'main') {
                if (currentAction === 'editPrice') {
                    const price = event.target.elements.price.value;
                    const secondPrice = event.target.elements.secondPrice.value;

                    setProductPrice(storeId, clickedProduct.id, price, secondPrice)
                        .then(toggleModal)
                        .then(onRequest)
                }

                if (currentAction === 'editStock') {
                    const stock = event.target.elements.stock.value;

                    // removeProductInStock(storeId, clickedProduct.id)
                    setProductInStock(storeId, clickedProduct.id, stock)
                        .then(toggleModal)
                        .then(onRequest)
                }
            }
        }

        else {

            if (currentAction === 'productDetails' && tab === 'review') {
                const comment = event.target.elements.comment.value;
                commentReviewProduct(clickedProduct.id, comment)
                    .then(toggleModal)
                    .then(onRequest)
            } else {

                // Получаем значение из поля ввода
                const measurementId = event.target.elements.measurementId.value;
                const manufacturerId = event.target.elements.manufacturerId.value;
                const name = event.target.elements.name.value;
                const description = event.target.elements.description.value;
                const imageURL = event.target.elements.imageURL.value;
                const subtitle = event.target.elements.subtitle.value;
                const article = event.target.elements.article.value;
                const size = event.target.elements.size.value;

                // Вызываем функцию newProduct с полученным значением
                newProduct(measurementId, manufacturerId, name, description, imageURL, subtitle, article, size)
                    .then(() => {
                        // Получаем все элементы input
                        let inputs = document.getElementsByTagName('input');

                        // Проходимся по каждому элементу input
                        for (let i = 0; i < inputs.length; i++) {
                            // Если тип поля text, email, password, etc., то очищаем его
                            if (inputs[i].type === 'text' || inputs[i].type === 'password' || inputs[i].type === 'email') {
                                inputs[i].value = ''; // Устанавливаем значение в пустую строку
                            }
                        }

                    });
            }
        }
    }

    const deleteProduct = async (id) => {
        clearError()
        return await delProduct(id)
            .then(onRequest)
    }

    const deleteReviewProduct = async (id) => {
        clearError()
        return await delReviewProduct(id)
            .then(onRequest)
    }

    const addReviewProduct = async (id) => {
        clearError()
        return await acceptReviewProduct(id)
            .then((res) => {
                setClickedProduct(prevState => ({
                    ...prevState,    // сохраняем все предыдущие свойства
                    id: res.id,     // обновляем поле id
                    status: 'created'   // обновляем поле status
                }));
                return res;  // возвращаем результат для следующего then
            })
            .then(() => setCurrentAction('addCategories'))
            .then(onRequest)
    }

    const openMainTab = () => {
        setTab("main");
    }

    const openReviewTab = () => {
        getMeasurements()
            .then(res => {
                setMeasurements(res.items)
            })
            .then(getComponents)
            .then(res => {
                setIngridients(res.items);
            })
        setTab("review");
    }

    const openSearchProductTab = () => {
        setTab("search");
    }

    const handlePriceChange = (e) => {
        const newPrice = e.target.value;
        setClickedProduct(prevState => ({
            ...prevState,
            price: newPrice
        }));
    }

    const handleSecondPriceChange = (e) => {
        const newSecondPrice = e.target.value;
        setClickedProduct(prevState => ({
            ...prevState,
            secondPrice: newSecondPrice
        }));
    }

    const handleStockChange = (e) => {
        const newStock = e.target.value;
        setClickedProduct(prevState => ({
            ...prevState,
            stock: newStock
        }));
    }

    const findManufacturerName = (uuid) => {
        const foundManufacturer = manufacturers.find(manufacturer => manufacturer.id === uuid);
        return foundManufacturer ? foundManufacturer.name : 'Загрузка...';
    }

    const findMeasurementName = (uuid) => {
        if (!uuid) {
            return 'ГР';
        }
        const foundMeasurement = measurements.find(measurement => measurement.id === uuid);
        return foundMeasurement ? foundMeasurement.name : 'Загрузка...';
    }

    const findComponentName = (uuid) => {
        const foundComponent = ingridients.find(component => component.id === uuid);
        return foundComponent ? foundComponent.name : 'Загрузка...';
    }

    const renderComponentInfo = (component, index) => {
        if (component.type?.name || component.type?.uuid) {
            return (
                <p key={index} style={{ 'marginTop': '-10px' }}>
                    <span className='text-light'>
                        {component.componentName ? component.componentName : findComponentName(component.componentId)}
                    </span>

                    {!!component.perVolume ?
                        <span className="text-light">
                            ({' ' + component.perVolume} {component.type.name ? component.type.name : findMeasurementName(component.type.uuid)})
                        </span>
                        : null
                    }
                    {<span className='text-light'>: </span>}
                    {component.amount}
                </p >
            );
        } else if (component.amount || component.perVolume || component.typeName) {
            return (
                <p key={index} style={{ 'marginTop': '-10px' }}>
                    <span className='text-light'>
                        {component.component}
                    </span>

                    {!!component.perVolume ?
                        <span className="text-light">
                            ({' ' + component.perVolume + ' ' + component.typeName})
                        </span>
                        : null
                    }
                    {<span className='text-light'>: </span>}
                    {component.amount}
                </p >
            );
        }

        return null;
    }



    const renderIngridients = (component, index, length) => {
        if (!component.type?.name && !component.type?.uuid && !component.amount && !component.typeName && !component.perVolume && !component.component) {
            return (
                <span key={index}>
                    {component.componentName ? component.componentName : findComponentName(component.componentId)}
                    {index !== length - 1 ? ', ' : '.'}
                </span>
            );
        } else if (!component.amount && !component.typeName && !component.perVolume) {
            return (
                <span key={index}>
                    {component.component}
                    {index !== length - 1 ? ', ' : '.'}
                </span>
            );
        }
        return null;
    }

    const renderCategories = (component, index, length) => {
        return (
            <span key={index}>
                {component.name}
                {index !== length - 1 ? ', ' : '.'}
            </span>
        );
    }

    const statusName = (status) => {

        switch (status) {
            case "waitForReview":
                return <span className='text-danger'>Ожидает проверки</span>;
            case "commented":
                return <span className='text-success'>Откомментирован</span>;
            case "created":
                return <span className='text-success'>Продукт создан</span>;
            default:
                return <span className='text-light'>Неизвестен</span>;
        }
    }

    const renderForm = () => {

        if (currentAction === 'productDetails' || currentAction === 'addCategories') {

            const resizeTextarea = (event) => {
                event.target.style.height = "auto";
                event.target.style.height = `${event.target.scrollHeight}px`;
            }

            const handleCategoryChange = async (selectedOptions) => {
                if (!selectedOptions) {
                    setSelectedCategories([]);
                    return;
                }

                let isSuccess = true; // предположим, что все запросы успешны по умолчанию
                const updatedIngridients = [];
                const previouslySelectedValues = prevSelectedCategoriesRef.current.map(cat => cat.value);
                const newSelectedValues = selectedOptions.map(option => option.value);

                for (const option of selectedOptions) {
                    if (!previouslySelectedValues.includes(option.value)) {
                        const response = await setCategory(option.value, clickedProduct.id);
                        if (!response.ok) {
                            isSuccess = false;
                        }
                    }

                    updatedIngridients.push({ 'label': option.label, 'value': option.value });
                }

                // Проверка на удаленные категории
                for (const prevOption of prevSelectedCategoriesRef.current) {
                    if (!newSelectedValues.includes(prevOption.value)) {
                        // вызывайте вашу функцию unsetCategory здесь
                        const response = await unsetCategory(prevOption.value, clickedProduct.id);
                        if (!response.ok) {
                            isSuccess = false;
                        }
                    }
                }

                if (isSuccess) {
                    setSelectedCategories(updatedIngridients);
                } else {
                    // здесь можно добавить обработку ошибки, например, показать пользователю уведомление
                }

            }

            return (clickedProduct ?
                <>
                    {/* Изображение */}
                    <a href={clickedProduct.imageURL} target="_blank" rel="noopener noreferrer">
                        <picture style={{ 'position': 'absolute', 'top': '38px', 'right': '80px' }}>
                            <img
                                src={clickedProduct.imageURL}
                                alt={'Фото ' + clickedProduct.name}
                                style={{ "height": '150px', 'width': '150px', 'borderRadius': "100%", "objectFit": "cover", "border": "1px solid #8F9092", 'cursor': 'pointer' }} />
                        </picture>
                    </a>
                    {/* Название */}
                    <h2>{clickedProduct.name}</h2>

                    {/* Статус */}
                    {
                        clickedProduct.status ?
                            <p style={{ 'marginTop': '-20px' }}>{statusName(clickedProduct.status)}</p>
                            : null
                    }

                    {/* Подпись */}
                    <p style={{ 'marginTop': '-20px' }}>{clickedProduct.subtitle}</p>
                    <p>
                        <span className='text-light'>Артикул:</span> {clickedProduct.article}
                    </p>

                    {/* Производитель */}
                    <p style={{ 'marginTop': '-10px' }}>
                        <span className='text-light'>Производитель:</span> {typeof (clickedProduct.manufacturer) === "object" ? (clickedProduct.manufacturer.name ? clickedProduct.manufacturer.name : findManufacturerName(clickedProduct.manufacturer.uuid)) : clickedProduct.manufacturer}
                    </p>

                    {/* Размер/Объем/Кол-во	 */}
                    <p style={{ 'marginTop': '-10px' }}>
                        <span className='text-light'>Размер/Объем/Кол-во:</span> {clickedProduct.size} {typeof (clickedProduct.measurement) === "object" ? (clickedProduct.measurement.name ? clickedProduct.measurement.name : findMeasurementName(clickedProduct.measurement.uuid)) : clickedProduct.measurement}
                    </p>

                    {/* Описание */}
                    <p style={{ 'marginTop': '-10px' }}>
                        <span className='text-light'>Описание:</span> {clickedProduct.description}
                    </p>

                    {/* Компоненты */}
                    {
                        clickedProduct.components?.length > 0 && (clickedProduct.components.map((component, index) => (
                            renderComponentInfo(component, index)
                        )))
                    }

                    {/* Состав */}
                    <p style={{ 'marginTop': '-10px' }}>
                        <span className="text-light">Состав: </span>
                        {
                            clickedProduct.components?.length > 0 && (clickedProduct.components.map((component, index) => (
                                renderIngridients(component, index, clickedProduct.components.length)
                            )))}
                    </p >

                    {/* Комментарии */}
                    {
                        (!!clickedProduct.comments && clickedProduct.comments.length > 0) ?
                            <>
                                <p className="text-light">Комментарии:</p>
                                {clickedProduct.comments.map((comment, index) => (
                                    <p key={index} style={{ 'marginTop': '-20px' }}>{comment.comment}</p>
                                ))}
                            </>
                            : ''
                    }

                    {currentAction === 'productDetails' && (role === 'partner' || role === 'admin') ?
                        <p style={{ 'marginTop': '-10px' }}>
                            <span className="text-light">Категории: </span>
                            {
                                clickedProduct.categories?.length > 0 && (clickedProduct.categories.map((category, index) => (
                                    renderCategories(category, index, clickedProduct.categories.length)
                                )))}
                        </p >
                        : null}

                    {
                        currentAction === 'productDetails' && role === 'superuser' && tab === 'review' ?
                            <div className="form-item product-descr">
                                <label htmlFor="form-descr" style={{ 'textTransform': "none" }}>Новый комментарий</label>
                                <textarea rows="1" onInput={resizeTextarea} required name="comment" className="form-control" id="form-descr"></textarea>
                            </div>
                            :
                            null
                    }

                    {
                        currentAction === 'productDetails' && role === 'superuser' && tab === 'review'
                            ?
                            <div className="modal-footer">
                                <button type="submit">Отклонить с комментарием</button>
                                <button id='asdasd' type="button"
                                    onClick={() => {
                                        addReviewProduct(clickedProduct.id)
                                        // toggleModal()
                                    }}
                                    className='approve-modal'>Одобрить</button>
                            </div>
                            : null
                    }

                    {
                        (currentAction === 'addCategories' && role === 'superuser')
                            ||
                            (currentAction === 'productDetails' && (role === 'superuser') && tab === 'main')
                            ?
                            <div className="form-item">
                                {console.log(clickedProduct.id)}
                                <label id="labelProductCategory" htmlFor="productCategory">Категории</label>
                                <Select
                                    value={selectedCategories}
                                    id="productCategory"
                                    isLoading={loading}
                                    placeholder="Выберите категорию"
                                    options={categoriesOptions}
                                    aria-labelledby="labelProductCategory"
                                    onChange={handleCategoryChange}
                                    noOptionsMessage={() => 'Не найдено'}
                                    isRequired
                                    isMulti
                                />
                            </div>
                            : null
                    }

                    {
                        (currentAction === 'addCategories' && role === 'superuser')
                            ||
                            (currentAction === 'productDetails' && (role === 'superuser' || role === 'admin') && tab === 'main')
                            ?
                            <div className="modal-footer" style={{ "gridTemplateColumns": "1fr" }}>
                                <button type="button" onClick={toggleModal}>Закрыть</button>
                            </div>
                            : null
                    }
                </>

                : <></>
            )
        }

        if (currentAction === 'createNewProduct') {

            // const sadasf = [
            //     {
            //         id: '1',
            //         name: 'Молочные продукты'
            //     },
            //     {
            //         id: '2',
            //         name: 'Йогурты --- Молочные продукты'
            //     },
            //     {
            //         id: '3',
            //         name: 'Творог --- Молочные продукты'
            //     },
            //     {
            //         id: '4',
            //         name: 'Сыр --- Молочные продукты'
            //     },
            //     {
            //         id: '5',
            //         name: 'Колбасы'
            //     },
            //     {
            //         id: '6',
            //         name: 'Копченые --- Колбасы'
            //     },
            //     {
            //         id: '7',
            //         name: 'Вареные --- Колбасы'
            //     },
            //     {
            //         id: '8',
            //         name: 'Овощи'
            //     },
            // ];

            const manufacturersOptions = manufacturers.map((item) => {
                return { value: item.id, label: item.name }
            })

            const measurementsOptions = measurements.map((item) => {
                return { value: item.id, label: item.name }
            })

            const componentsOptions = ingridients
                .filter(item =>
                    item.name.toLowerCase() !== "срок хранения" &&
                    item.name.toLowerCase() !== "ккал" &&
                    item.name.toLowerCase() !== "белки" &&
                    item.name.toLowerCase() !== "жиры" &&
                    item.name.toLowerCase() !== "углеводы"
                )
                .map((item) => {
                    return { value: item.id, label: item.name }
                })

            const handleManufacturerChange = (selectedOption) => {
                if (selectedOption.__isNew__) {
                    // Пользователь создал новый элемент.
                    setSelectedManufacturer(
                        {
                            "uuid": null,
                            "name": selectedOption.label
                        }
                    );
                } else {
                    // Это существующий элемент.
                    setSelectedManufacturer(
                        {
                            "uuid": selectedOption.value,
                            "name": null
                        }
                    );
                }
            };

            const handleMeasurementChange = (selectedOption) => {
                if (selectedOption.__isNew__) {
                    // Пользователь создал новый элемент.
                    setSelectedMeasurement(
                        {
                            "uuid": null,
                            "name": selectedOption.label
                        }
                    );
                } else {
                    // Это существующий элемент.
                    setSelectedMeasurement(
                        {
                            "uuid": selectedOption.value,
                            "name": null
                        }
                    );
                }
            };

            const handleExpMeasurementChange = (selectedOption) => {
                if (selectedOption.__isNew__) {
                    // Пользователь создал новый элемент.
                    setSelectedExpMeasurement(
                        {
                            "uuid": null,
                            "name": selectedOption.label
                        }
                    );
                } else {
                    // Это существующий элемент.
                    setSelectedExpMeasurement(
                        {
                            "uuid": selectedOption.value,
                            "name": null
                        }
                    );
                }
            };

            const handleIngridientsChange = (selectedOptions) => {
                if (selectedOptions) {
                    let updatedIngridients = [];
                    // Если выбран хотя бы один элемент
                    selectedOptions.forEach(option => {
                        if (option.__isNew__) {
                            updatedIngridients.push({ 'label': option.label, 'value': null });
                        } else {
                            updatedIngridients.push({ 'label': option.label, 'value': option.value });
                        }
                    });

                    setSelectedIngridients(updatedIngridients);
                } else {
                    setSelectedIngridients([])
                }
            };

            const validateImageUrl = (url) => {
                return new Promise((resolve, reject) => {
                    var img = new Image();
                    img.onload = () => resolve(true);
                    img.onerror = () => reject(false);
                    img.src = url;
                });
            }

            const handleImageUpdate = async () => {
                const imageURL = document.getElementById('productImage').value;
                try {
                    await validateImageUrl(imageURL);
                    // Если URL действителен, обновляем превью изображения
                    document.querySelector('.uploadImg picture img').src = imageURL;
                    document.querySelector('.uploadImg .error-message').classList.add('d-none');
                } catch (error) {
                    document.querySelector('.uploadImg picture img').src = imgIcon;
                    // Если URL не действителен, выводим сообщение об ошибке
                    document.querySelector('.uploadImg .error-message').classList.remove('d-none');
                    // alert("Пожалуйста, введите действительную ссылку на изображение.");
                }
            }

            const resizeTextarea = (event) => {
                event.target.style.height = "auto";
                event.target.style.height = `${event.target.scrollHeight}px`;
            }

            if (role === 'superuser') {
                return (
                    <>
                        <div className="form-item">
                            <label htmlFor="productName">Название</label>
                            <input id="productName" required name="name" type="text" defaultValue="" placeholder='Молоко' />
                        </div>

                        <div className="form-item">
                            <label htmlFor="productSubtitle">Подпись</label>
                            <input id="productSubtitle" required name="subtitle" type="text" defaultValue="" placeholder='' />
                        </div>

                        <div className="form-item">
                            <label id="labelProductManufacturer" htmlFor="productManufacturer">Производитель</label>
                            <Select
                                id="productManufacturer"
                                isLoading={loading}
                                placeholder="Выберите производителя"
                                // placeholder="Выберите категорию"
                                options={manufacturersOptions}
                                aria-labelledby="labelProductManufacturer"
                                onChange={handleManufacturerChange}
                                isRequired
                            />
                            <input type="hidden" name="manufacturerId" value={selectedManufacturer?.uuid ?? ''} />
                        </div>

                        <div className="form-item product-descr">
                            <label htmlFor="form-descr">Описание</label>
                            <textarea rows="1" onInput={resizeTextarea} required name="description" className="form-control" id="form-descr"></textarea>
                        </div>

                        <div className="uploadImg">
                            <div className="form-item">
                                <label htmlFor="productImage">Ссылка на изображение</label>
                                <input id="productImage" required name="imageURL" type="text" defaultValue="" placeholder='' />
                                <span className="error-message d-none">Ссылка недействительна.</span>
                            </div>
                            <picture>
                                <img src={imgIcon} alt="Капуста" />
                            </picture>
                            <div className="form-item">
                                <button type="button" className="btn" onClick={handleImageUpdate}>Обновить</button>
                            </div>
                        </div>

                        <div className="form-item">
                            <label htmlFor="productArticle">Артикул</label>
                            <input id="productArticle" required name="article" type="text" defaultValue="" placeholder='' />
                        </div>
                        <div className="article-and-size">


                            <div className="form-item">
                                <label htmlFor="productSize">Размер/Объем/Кол-во</label>
                                <input id="productSize" required name="size" type="number" defaultValue="" placeholder='' />
                            </div>

                            <div className="form-item">
                                <label id="labelProductMeasurement" htmlFor="productMeasurement">ед.</label>
                                <Select
                                    id="productMeasurement"
                                    isLoading={loading}
                                    placeholder="Выберите"
                                    options={measurementsOptions}
                                    aria-labelledby="labelProductMeasurement"
                                    onChange={handleMeasurementChange}
                                    isRequired
                                />
                                <input type="hidden" name="measurementId" value={selectedMeasurement?.uuid ?? ''} />
                            </div>
                        </div>

                        <div className="form-item submit">
                            <button type="submit">Сохранить</button>
                        </div>
                    </>
                )
            }

            if (role === "partner") {
                return (
                    <>
                        <div className="form-item">
                            <label htmlFor="productName">Название</label>
                            <input id="productName" required name="name" type="text" defaultValue="" placeholder='' />
                        </div>

                        <div className="form-item">
                            <label htmlFor="productSubtitle">Подпись</label>
                            <input id="productSubtitle" required name="subtitle" type="text" defaultValue="" placeholder='' />
                        </div>

                        <div className="form-item">
                            <label id="labelProductManufacturer" htmlFor="productManufacturer">Производитель</label>
                            <CreatableSelect
                                id="productManufacturer"
                                isLoading={loading}
                                placeholder="Выберите производителя"
                                options={manufacturersOptions}
                                aria-labelledby="labelProductManufacturer"
                                onChange={handleManufacturerChange}
                                formatCreateLabel={(inputValue) => `Добавить производителя "${inputValue}"`}
                                noOptionsMessage={() => 'Введите название производителя'}
                                isRequired
                            />
                        </div>

                        <div className="form-item product-descr">
                            <label htmlFor="form-descr">Описание</label>
                            <textarea rows="1" onInput={resizeTextarea} required name="description" className="form-control" id="form-descr"></textarea>
                        </div>

                        <div className="uploadImg">
                            <div className="form-item">
                                <label htmlFor="productImage">Ссылка на изображение</label>
                                <input id="productImage" required name="imageURL" type="text" defaultValue="" placeholder='' />
                                <span className="error-message d-none">Ссылка недействительна.</span>
                            </div>
                            <picture>
                                <img src={imgIcon} alt="Капуста" />
                            </picture>
                            <div className="form-item">
                                <button type="button" className="btn" onClick={handleImageUpdate}>Обновить</button>
                            </div>
                        </div>

                        <div className="form-item">
                            <label id="labelProductMeasurement" htmlFor="productMeasurement">Состав</label>
                            <CreatableSelect
                                id="productMeasurement"
                                isLoading={loading}
                                placeholder="Введите состав"
                                options={componentsOptions}
                                aria-labelledby="labelProductMeasurement"
                                onChange={handleIngridientsChange}
                                formatCreateLabel={(inputValue) => `Добавить "${inputValue}"`}
                                noOptionsMessage={() => 'Введите ингридиент'}
                                isRequired
                                isMulti
                            />
                        </div>

                        <div className="form-item">
                            <label htmlFor="productArticle">Артикул</label>
                            <input id="productArticle" required name="article" type="text" defaultValue="" placeholder='' />
                        </div>


                        <div className="article-and-size">
                            <div className="form-item">
                                <label htmlFor="productSize">Размер/Объем/Кол-во</label>
                                <input id="productSize" required name="size" type="number" defaultValue="" placeholder='' />
                            </div>

                            <div className="form-item">
                                <label id="labelProductMeasurement" htmlFor="productMeasurement">ед.</label>
                                <CreatableSelect
                                    id="productMeasurement"
                                    isLoading={loading}
                                    placeholder="Выберите"
                                    options={measurementsOptions}
                                    aria-labelledby="labelProductMeasurement"
                                    onChange={handleMeasurementChange}
                                    formatCreateLabel={(inputValue) => `Добавить единицу измерения "${inputValue}"`}
                                    noOptionsMessage={() => 'Введите единицу измерения'}
                                    isRequired
                                />
                            </div>
                        </div>

                        <div className="article-and-size">
                            <div className="form-item">
                                <label htmlFor="productExp">Срок хранения</label>
                                <input id="productExp" required name="exp" type="number" defaultValue="" placeholder='' />
                            </div>

                            <div className="form-item">
                                <label id="labelProductExpMeasurement" htmlFor="productExpMeasurement">ед.</label>
                                <CreatableSelect
                                    id="productExpMeasurement"
                                    isLoading={loading}
                                    placeholder="Выберите"
                                    options={measurementsOptions}
                                    aria-labelledby="labelProductExpMeasurement"
                                    onChange={handleExpMeasurementChange}
                                    formatCreateLabel={(inputValue) => `Добавить единицу измерения "${inputValue}"`}
                                    noOptionsMessage={() => 'Введите единицу измерения'}
                                    isRequired
                                />
                            </div>
                        </div>

                        <div className="kcal">

                            <div className="form-item">
                                <label htmlFor="kcal">ККАЛ*</label>
                                <input id="kcal" name="kcal" type="number" defaultValue="" placeholder='' />
                            </div>

                            <div className="form-item">
                                <label htmlFor="protein">БЕЛКИ*</label>
                                <input id="protein" name="protein" type="number" defaultValue="" placeholder='' />
                            </div>

                            <div className="form-item">
                                <label htmlFor="fat">ЖИРЫ*</label>
                                <input id="fat" name="fat" type="number" defaultValue="" placeholder='' />
                            </div>

                            <div className="form-item">
                                <label htmlFor="carbos">УГЛЕВОДЫ*</label>
                                <input id="carbos" name="carbos" type="number" defaultValue="" placeholder='' />
                            </div>

                        </div>

                        <p>* Укажите информацию о продукте на 100 г</p>

                        <div className="form-item submit">
                            <button type="submit">Сохранить</button>
                        </div>
                    </>
                )
            }
        }

        if ((currentAction === 'editStock') && (role === 'partner' || role === 'admin')) {
            return (
                <>
                    <div className="form-item">
                        <label>Наличие</label>
                        <input name="stock" type="text" value={clickedProduct.stock}
                            onChange={handleStockChange} required />
                    </div>

                    <input type="hidden" name="clickedProductId" value={clickedProduct?.id ?? ''} />

                    <div className="form-item submit">
                        <button type="submit">Сохранить</button>
                    </div>
                </>
            )
        }

        if ((currentAction === 'editPrice') && (role === 'partner' || role === 'admin')) {
            return (
                <>
                    <>
                        <div className="form-item">
                            <label>Цена</label>
                            <input name="price" type="text" value={clickedProduct.price}
                                onChange={handlePriceChange} required />
                        </div>

                        <div className="form-item">
                            <label>Цена со скидкой (если есть)</label>
                            <input name="secondPrice" type="text" value={clickedProduct.secondPrice ? clickedProduct.secondPrice : null}
                                onChange={handleSecondPriceChange} />
                        </div>

                        <input type="hidden" name="clickedProductId" value={clickedProduct?.id ?? ''} />

                        <div className="form-item submit">
                            <button type="submit">Сохранить</button>
                        </div>
                    </>
                </>
            )
        }

        if (role === 'partner' || role === 'admin') {
            return (
                <>
                    <div className="form-item">
                        <label>Наличие</label>
                        <input name="stock" type="text" defaultValue="" required />
                    </div>

                    <div className="form-item">
                        <label>Цена</label>
                        <input name="price" type="text" defaultValue="" required />
                    </div>

                    <div className="form-item">
                        <label>Цена со скидкой (если есть)</label>
                        <input name="secondPrice" type="text" defaultValue="" />
                    </div>

                    <input type="hidden" name="clickedProductId" value={clickedProduct?.id ?? ''} />

                    <div className="form-item submit">
                        <button type="submit">Сохранить</button>
                    </div>
                </>
            )
        }

        return (<></>)
    }

    const createProductsComponent = (productsList) => {

        return !(!productsList) && !error ?
            <Products
                products={productsList}
                addReviewProduct={addReviewProduct}
                reviewProducts={reviewProducts}
                deleteReviewProduct={deleteReviewProduct}
                deleteProduct={deleteProduct}
                selectedProduct={selectedProduct}
                addProductToStore={addProductToStore}
                tab={tab}
                editStock={editStock}
                editPrice={editPrice}
                measurements={measurements}
                manufacturers={manufacturers}
                openProductDetails={openProductDetails}
                findManufacturerName={findManufacturerName}
                findMeasurementName={findMeasurementName}
                statusName={statusName}
            />
            : null;
    }

    const getCategoryProduct = (id) => {
        setActiveCategoryId(id)

        if (role === 'partner' || role === 'admin') {
            getCategory(id, storeId)
                .then(res => setMyProducts(res.products));
        } else if (role === 'superuser') {
            getCategory(id)
                .then(res => setProducts(res.products));
        }
    }

    const handleAllClick = () => {
        setActiveCategoryId(null);
        onRequest()
    }

    const Filter = ({ items }) => {
        return (
            <div className="categories">
                <span>По разделам:</span>
                <button type='button' className={activeCategoryId === null ? 'active' : ''} onClick={handleAllClick}>Все</button>
                {items.map(category => (
                    <button
                        type='button'
                        key={category.id}
                        className={activeCategoryId === category.id ? 'active' : ''}
                        onClick={() => getCategoryProduct(category.id)}
                    >{category.name}</button>
                ))}
            </div>
        );
    }

    const items = createProductsComponent(role === 'superuser' ? filteredProducts : myFilteredProducts);
    const searchItems = createProductsComponent(filteredProducts);
    const reviewItems = createProductsComponent(reviewProducts);

    const errorMessage = error ? <ErrorMessage /> : null;
    const skeleton = loading && firstLoad ? <Skeleton pageName={"ProductList"} /> : null;

    return (

        <>
            <div className="container d-flex justify-content-between align-items-center">
                <ul className="breadcrumbs">
                    {tab === 'main' ?

                        <li>
                            <h1 style={{ "padding": "20px 0", "margin": "-20px 0", "lineHeight": "normal" }}>Товары</h1>
                        </li>
                        :
                        <li>
                            <button type="button" className='tab' style={{ "color": "#8F9092" }} onClick={openMainTab}><h1>Товары</h1></button>
                        </li>
                    }

                    {(role === 'partner' || role === 'admin') && tab === 'search' ?
                        <li>
                            <h1 style={{ "padding": "20px 0", "margin": "-20px 0", "lineHeight": "normal" }}>/ Добавить товар</h1>
                        </li>
                        : null}
                </ul>
                {!error
                    ?
                    <div className="wrapper d-flex align-items-center" style={{ "gap": "20px" }}>

                        {
                            role === "superuser" && location.pathname.includes('/products')
                                ?
                                <>
                                    <button onClick={openReviewTab} style={tab === "review" ? { "color": "#000", "borderBottom": "3px solid #9DBDB0" } : null} className="top__order tab" type="button">Новые товары ({!!reviewProducts ? reviewProducts.length : 0})</button>
                                    <button className="top__add" onClick={() => {
                                        setCurrentAction('createNewProduct')
                                        onFormRequest()
                                        toggleModal()
                                    }}>+ Добавить</button>
                                </>
                                :
                                null
                        }

                        {
                            role === "partner" ?
                                tab === 'main' ?
                                    <>
                                        <button className="top__add" onClick={openSearchProductTab}>+ Добавить</button>
                                    </> : null
                                :
                                null
                        }
                    </div>
                    :
                    null
                }

            </div>

            {errorMessage}

            {
                !error ?
                    <>
                        <div className="catalog">
                            <div className="container">

                                <div className="filter">
                                    {tab === "search" || tab === "main" ?
                                        <Filter items={categories} />
                                        : null
                                    }

                                    {tab === "search" || tab === "main" ?
                                        <Search
                                            manufacturers={manufacturers}
                                            products={products}
                                            selectedManufacturer={selectedListManufacturer}
                                            setSelectedManufacturer={setSelectedListManufacturer}
                                            selectedProduct={selectedProduct}
                                            setSelectedProduct={setSelectedProduct}
                                            loading={loading}
                                            setFilteredProducts={setFilteredProducts}
                                        />
                                        : null}
                                </div>


                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ "width": "67px" }} scope="col" className='text-left'>Фото</th>
                                            <th scope="col" className='text-left'>Название</th>
                                            <th scope="col">Производитель</th>
                                            <th style={{ "width": "200px" }} scope="col">Размер/Объем/Кол-во</th>
                                            <th scope="col">Артикул</th>

                                            {((role === 'partner' || role === 'admin') && tab === 'main') || (role === 'superuser' && location.pathname.includes('/shops/')) ?
                                                <>
                                                    <th style={{ "width": "65px" }} scope="col">Цена</th>
                                                    <th style={{ "width": "65px", 'whiteSpace': 'nowrap' }} scope="col">В наличии</th>
                                                    <th style={{ "width": "65px", 'whiteSpace': 'nowrap' }} scope="col">Информация</th>
                                                </>

                                                : null}

                                            {tab === "review" ?
                                                <th scope="col">Статус</th> : null}

                                            {role === 'superuser' ?
                                                <th style={{ "width": "65px" }} scope="col">Удалить</th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {skeleton}
                                        {tab === "main" ? items : null}
                                        {tab === "review" ? reviewItems : null}
                                        {tab === "search" ? searchItems : null}

                                        {tab === 'search' ?
                                            <tr>
                                                <td colSpan={6} className='text-center'>
                                                    <p>Не нашли то, что искали? Вы можете</p>
                                                    <button type="button" className='btn btn-primary m-auto' onClick={openModalNewProductReview}>Создать новый продукт</button>
                                                    <p><small>Мы проверим корректность информации о продукте и тогда вы сможете добавить его к себе в магазин.</small></p>
                                                </td>
                                            </tr> : null
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <Modal modalIsOpen={modalIsOpen} toggleModal={toggleModal} renderForm={renderForm} handleFormSubmit={handleFormSubmit} />
                    </>
                    : null
            }
        </>
    )
}

export default ProductListPage;