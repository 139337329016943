import { useCookies } from 'react-cookie';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useBazarkenService from '../../services/BazarkenService';

import './styles/loginPage.scss';
import logo from '../../resources/img/icons/logo.svg'

const MePage = () => {
    const [formState, setFormState] = useState({ password: "" });
    const { error, login, clearError, getMe, setPassword, delPassword } = useBazarkenService();
    const [cookies, setCookie] = useCookies(['user']);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        clearError();
        setPassword(formState.password)
            // delPassword(formState.password)
            .then(() => {
                // Установите shouldUpdatePassword в false после успешного выполнения
                const updatedUser = { ...cookies.user, user: {...cookies.user.user, shouldUpdatePassword: false} };
                setCookie('user', JSON.stringify(updatedUser), { path: '/' });

                // navigate('/');
            })
    };

    const handleInputChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        onRequest();
    }, []) // Если подставить пустой массив то функция вызовется только один раз

    const onRequest = () => {
        getMe()
            .then(res => {
                // console.log(res);
            })
    }

    return (
        <div className="login-page">
            <div className="login-page__body">

                <div className="login-page__top">
                    <img src={logo} alt="" />
                    <h2>Придумайте пароль</h2>
                </div>

                <form className="login-page__form" onSubmit={handleSubmit}>

                    <div className="form-item password">
                        <label htmlFor="password">Пароль</label>

                        <input id="password" type="password" name="password" value={formState.password} onChange={handleInputChange} />

                        <svg style={{ "margin": "0 1px 0 2px" }} width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1672_8578)">
                                <path d="M2.19727 19.1602H11.1328C12.5684 19.1602 13.3301 18.3789 13.3301 16.8359V10.1074C13.3301 8.56445 12.5684 7.79297 11.1328 7.79297H2.19727C0.761719 7.79297 0 8.56445 0 10.1074V16.8359C0 18.3789 0.761719 19.1602 2.19727 19.1602ZM1.70898 8.54492H3.26172V5.24414C3.26172 2.7832 4.83398 1.47461 6.66016 1.47461C8.48633 1.47461 10.0781 2.7832 10.0781 5.24414V8.54492H11.6211V5.45898C11.6211 1.78711 9.21875 0 6.66016 0C4.11133 0 1.70898 1.78711 1.70898 5.45898V8.54492Z" fill="black" fillOpacity="0.85" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1672_8578">
                                    <rect width="13.3301" height="19.6777" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <div className={`error-message`}>
                        <small>{ }</small>
                    </div>


                    <div className="form-item" style={{ "marginTop": "26px" }}>
                        <div className="form-item submit">
                            <input type="submit" value="Сохранить пароль" />
                        </div>
                    </div>
                </form>
            </div >
        </div >
    )
}

export default MePage;